import React, {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Message, Modal, Menu, Space, Button, Dropdown} from "@arco-design/web-react";
import {IconSun, IconMoon, IconUser, IconCheck, IconLock, IconPoweroff} from "@arco-design/web-react/icon";
import PropTypes from "prop-types";

import Util from "../../js/util";

import ImageEn from "../../image/en.svg";
import ImageZh from "../../image/zh.svg";

import Password from "../password";

const HelpIndex = ({outletContext = {}, className = "help", isLogin = true, ...props}) => {
    const dispatch = useDispatch();
    const main = useSelector((store) => store.main);
    const request = useRef(false);
    const controller = new AbortController();
    const [isPassword, setIsPassword] = useState(false);

    useEffect(() => {
        return () => {
            controller.abort();
        };
    }, []);

    const handleLogout = () => {
        if (request.current) {
            return;
        }

        Modal.confirm({
            title: outletContext.intl.formatMessage({id: "global.logoutConfirmTitle"}),
            content: outletContext.intl.formatMessage({id: "global.logoutConfirmContent"}),
            okButtonProps: {
                status: "danger",
            },
            onOk: () => {
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                Util.request(outletContext, {
                    url: props.logoutUrl,
                    controller: controller,
                    data: {},
                    success: (response) => {
                        localStorage.removeItem("authorization");

                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;

                        setTimeout(() => {
                            Util.redirectTo(props, {
                                url: "/login",
                            });
                        }, 300);
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                    },
                    complete: () => {},
                });
            },
        });
    };

    return (
        <React.Fragment>
            <Space className={className}>
                <Dropdown
                    droplist={
                        <Menu
                            onClickMenuItem={(key) => {
                                if (main.locale == key) {
                                    return;
                                }

                                localStorage.setItem("locale", key);

                                if (props.isDrawer) {
                                    props.setIsDrawer(false);

                                    setTimeout(() => {
                                        dispatch({
                                            type: "main",
                                            data: {
                                                locale: key,
                                            },
                                        });
                                    }, 100);
                                } else {
                                    dispatch({
                                        type: "main",
                                        data: {
                                            locale: key,
                                        },
                                    });
                                }

                                Util.emitter.emit("locale", {locale: key});
                            }}
                        >
                            <Menu.Item key="zh">
                                <Space>{main.locale == "zh" ? <IconCheck /> : <div className="help_check"></div>}简体中文</Space>
                            </Menu.Item>
                            <Menu.Item key="en">
                                <Space>{main.locale == "en" ? <IconCheck /> : <div className="help_check"></div>}English</Space>
                            </Menu.Item>
                        </Menu>
                    }
                    trigger="click"
                >
                    <Button
                        shape="circle"
                        icon={main.locale == "zh" ? <ImageZh className="arco-icon help_icon" /> : <ImageEn className="arco-icon help_icon" />}
                    ></Button>
                </Dropdown>
                <Dropdown
                    droplist={
                        <Menu
                            onClickMenuItem={(key) => {
                                if (main.theme == key) {
                                    return;
                                }

                                if (key == "light") {
                                    document.body.removeAttribute("arco-theme");
                                } else if (key == "dark") {
                                    document.body.setAttribute("arco-theme", "dark");
                                }

                                localStorage.setItem("theme", key);

                                if (props.isDrawer) {
                                    props.setIsDrawer(false);

                                    setTimeout(() => {
                                        dispatch({
                                            type: "main",
                                            data: {
                                                theme: key,
                                            },
                                        });
                                    }, 100);
                                } else {
                                    dispatch({
                                        type: "main",
                                        data: {
                                            theme: key,
                                        },
                                    });
                                }
                            }}
                        >
                            <Menu.Item key="light">
                                <Space>
                                    {main.theme == "light" ? <IconCheck /> : <div className="help_check"></div>}
                                    {outletContext.intl.formatMessage({id: "global.light"})}
                                </Space>
                            </Menu.Item>
                            <Menu.Item key="dark">
                                <Space>
                                    {main.theme == "dark" ? <IconCheck /> : <div className="help_check"></div>}
                                    {outletContext.intl.formatMessage({id: "global.dark"})}
                                </Space>
                            </Menu.Item>
                        </Menu>
                    }
                    trigger="click"
                >
                    <Button shape="circle" icon={main.theme == "dark" ? <IconMoon /> : <IconSun />}></Button>
                </Dropdown>
                {isLogin ? (
                    <Dropdown
                        droplist={
                            <Menu
                                onClickMenuItem={(key) => {
                                    if (key == "changePassword") {
                                        if (props.isDrawer) {
                                            props.setIsDrawer(false);

                                            setTimeout(() => {
                                                setIsPassword(true);
                                            }, 100);
                                        } else {
                                            setIsPassword(true);
                                        }
                                    } else if (key == "logout") {
                                        if (props.isDrawer) {
                                            props.setIsDrawer(false);

                                            setTimeout(() => {
                                                handleLogout();
                                            }, 100);
                                        } else {
                                            handleLogout();
                                        }
                                    }
                                }}
                            >
                                <Menu.Item key="changePassword">
                                    <Space>
                                        <IconLock />
                                        {outletContext.intl.formatMessage({id: "global.changePassword"})}
                                    </Space>
                                </Menu.Item>
                                <Menu.Item key="logout">
                                    <Space>
                                        <IconPoweroff />
                                        {outletContext.intl.formatMessage({id: "global.logout"})}
                                    </Space>
                                </Menu.Item>
                            </Menu>
                        }
                        trigger="click"
                    >
                        <Button shape="circle" icon={<IconUser />}></Button>
                    </Dropdown>
                ) : null}
            </Space>
            {isPassword ? (
                <Password
                    outletContext={outletContext}
                    visible={isPassword}
                    changePasswordUrl={props.changePasswordUrl}
                    onClose={() => {
                        if (props.isDrawer) {
                            props.setIsDrawer(false);

                            setTimeout(() => {
                                setIsPassword(false);
                            }, 100);
                        } else {
                            setIsPassword(false);
                        }
                    }}
                    onSubmit={() => {
                        if (props.isDrawer) {
                            props.setIsDrawer(false);

                            setTimeout(() => {
                                setIsPassword(false);
                            }, 100);
                        } else {
                            setIsPassword(false);
                        }
                    }}
                />
            ) : null}
        </React.Fragment>
    );
};

HelpIndex.propTypes = {
    outletContext: PropTypes.object.isRequired,
    className: PropTypes.string,
    logoutUrl: PropTypes.string.isRequired,
    changePasswordUrl: PropTypes.string.isRequired,
    isDrawer: PropTypes.bool.isRequired,
    setIsDrawer: PropTypes.func.isRequired,
    isLogin: PropTypes.bool,
};

export default Util.withRouter(HelpIndex);
