import React, {useState, useRef, useEffect} from "react";
import ReactDOM from "react-dom/client";
import {legacy_createStore, combineReducers} from "redux";
import {HashRouter, Route, Routes} from "react-router-dom";
import {Provider} from "react-redux";
import {routerReducer} from "react-router-redux";
import {AliveScope} from "react-activation";
import {IntlProvider} from "react-intl";
import {ConfigProvider} from "@arco-design/web-react";
import ArcoZh from "@arco-design/web-react/es/locale/zh-CN";
import ArcoEn from "@arco-design/web-react/es/locale/en-US";
import {initVChartArcoTheme} from "@visactor/vchart-arco-theme";
import {SortableProvider} from "use-sortablejs";

import "@arco-design/web-react/dist/css/index.less";
import "../../../nowui-common/css/index.css";

import Global from "./view/global";
import Login from "./view/login";
import Main from "./view/main";
import NotFound from "../../../nowui-common/component/notfound";

import Util from "../../../nowui-common/js/util";

import IntlZh from "../../../nowui-common/locale/zh";
import IntlEn from "../../../nowui-common/locale/en";

let locale = localStorage.getItem("locale");
if (locale) {
} else {
    localStorage.setItem("locale", "zh");
}

let theme = localStorage.getItem("theme");
if (theme) {
    if (theme === "dark") {
        document.body.setAttribute("arco-theme", "dark");
    } else {
        document.body.removeAttribute("arco-theme");
    }
} else {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    if (darkThemeMq.matches) {
        document.body.setAttribute("arco-theme", "dark");

        localStorage.setItem("theme", "dark");
    } else {
        document.body.removeAttribute("arco-theme");

        localStorage.setItem("theme", "light");
    }
}

let zh = IntlZh;
let zhContexts = require.context("./view/", true, /^.*\/zh\.json$/);
zhContexts.keys().forEach((fileName) => {
    zh = Object.assign({}, zh, zhContexts(fileName));
});

let en = IntlEn;
let enContexts = require.context("./view/", true, /^.*\/en\.json$/);
enContexts.keys().forEach((fileName) => {
    en = Object.assign({}, en, enContexts(fileName));
});

const intlMap = {
    zh: zh,
    en: en,
};

const arcoMap = {
    zh: ArcoZh,
    en: ArcoEn,
};

const reducers = {};
let storeContexts = require.context("./view/", true, /^.*\/store\.jsx$/);
storeContexts.keys().forEach((fileName) => {
    reducers[storeContexts(fileName).default.name] = storeContexts(fileName).default.reducer;
});

const stores = legacy_createStore(
    combineReducers(
        Object.assign(reducers, {
            routing: routerReducer,
        })
    )
);

let routers = [];
let routerContexts = require.context("./view/", true, /^.*\/router\.jsx$/);
routerContexts.keys().forEach((fileName) => {
    routers = routers.concat(routerContexts(fileName).default);
});

initVChartArcoTheme();

const App = ({...props}) => {
    const [locale, setLanguage] = useState(localStorage.getItem("locale"));

    useEffect(() => {
        Util.emitter.on("locale", handleLocale);

        Util.emitter.on("language", handleLanguage);

        return () => {
            Util.emitter.off("locale", handleLocale);

            Util.emitter.off("language", handleLanguage);
        };
    }, []);

    const handleLocale = (event) => {
        setLanguage(event.locale);
    };

    const handleLanguage = (event) => {
        for (let i = 0; i < event.languageList.length; i++) {
            let language = event.languageList[i];
            intlMap.zh[language.languageId] = language.zh;
            intlMap.en[language.languageId] = language.en;
        }
    };

    return (
        <React.Fragment>
            <SortableProvider>
                <IntlProvider locale={locale} messages={intlMap[locale]}>
                    <ConfigProvider
                        locale={arcoMap[locale]}
                        componentConfig={{
                            Form: {
                                validateMessages: {
                                    required: (data, {label}) => intlMap[locale]["global.required"],
                                    type: {
                                        string: "#{field} 不是合法的文本类型",
                                        number: "#{field} 不是合法的数字类型",
                                        boolean: "#{field} 不是合法的布尔类型",
                                        array: "#{field} 不是合法的数组类型",
                                        object: "#{field} 不是合法的对象类型",
                                        url: "#{field} 不是合法的 url 地址",
                                        email: "#{field} 不是合法的邮箱地址",
                                        ip: "#{field} 不是合法的 IP 地址",
                                    },
                                    number: {
                                        min: "`#{value}` 小于最小值 `#{min}`",
                                        max: "`#{value}` 大于最大值 `#{max}`",
                                        equal: "`#{value}` 不等于 `#{equal}`",
                                        range: "`#{value}` 不在 `#{min} ~ #{max}` 范围内",
                                        positive: "`#{value}` 不是正数",
                                        negative: "`#{value}` 不是负数",
                                    },
                                    array: {
                                        length: "`#{field}` 个数不等于 #{length}",
                                        minLength: "`#{field}` 个数最少为 #{minLength}",
                                        maxLength: "`#{field}` 个数最多为 #{maxLength}",
                                        includes: "#{field} 不包含 #{includes}",
                                        deepEqual: "#{field} 不等于 #{deepEqual}",
                                        empty: "`#{field}` 不是空数组",
                                    },
                                    string: {
                                        minLength: "字符数最少为 #{minLength}",
                                        maxLength: "字符数最多为 #{maxLength}",
                                        length: "字符数必须是 #{length}",
                                        match: "`#{value}` 不符合模式 #{pattern}",
                                        uppercase: "`#{value}` 必须全大写",
                                        lowercase: "`#{value}` 必须全小写",
                                    },
                                    object: {
                                        deepEqual: "`#{field}` 不等于期望值",
                                        hasKeys: "`#{field}` 不包含必须字段",
                                        empty: "`#{field}` 不是对象",
                                    },
                                    boolean: {
                                        true: "期望是 `true`",
                                        false: "期望是 `false`",
                                    },
                                },
                            },
                        }}
                    >
                        <Provider store={stores}>
                            <AliveScope>
                                <HashRouter>
                                    <Routes>
                                        <Route path="/" element={<Global />}>
                                            <Route path="/login" element={<Login />} />
                                            <Route path="/" element={<Main />}>
                                                {routers.map((router) => {
                                                    return router;
                                                })}
                                            </Route>
                                        </Route>
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                </HashRouter>
                            </AliveScope>
                        </Provider>
                    </ConfigProvider>
                </IntlProvider>
            </SortableProvider>
        </React.Fragment>
    );
};

let root;
function render(props) {
    const {container} = props;
    root = ReactDOM.createRoot(container ? container.querySelector("#root") : document.querySelector("#root"));
    root.render(
        <React.Fragment>
            <App />
        </React.Fragment>
    );
}

if (!window.__POWERED_BY_QIANKUN__) {
    render({});
}

export async function bootstrap() {}

export async function mount(props) {
    Util.emitter = props.emitter;
    Util.emitter.emit("loading", {isLoad: false});

    render(props);
}

export async function unmount(props) {
    root.unmount();
}
