import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {Message, Modal, Breadcrumb, Card, Grid, Form, Space, Button, Input, Select, Table} from "@arco-design/web-react";
import {IconRefresh, IconClose, IconCloseCircle, IconSave, IconCheckCircle, IconDelete} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import {useOutletContext} from "react-router-dom";
import PropTypes from "prop-types";

import Util from "../../../../../nowui-common/js/util";

import Error from "../../../../../nowui-common/component/error";
import Loading from "../../../../../nowui-common/component/loading";

import DepartmentSelect from "../department/select";
import RoleSelect from "../role/select";

const UserDetail = ({...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [breadcrumb, setBreadcrumb] = useState({});
    const [breadcrumbList, setBreadcrumbList] = useState([]);
    const [isDepartment, setIsDepartment] = useState(false);
    const [departmentList, setDepartmentList] = useState([]);
    const [departmentIdList, setDepartmentIdList] = useState([]);
    const [isRole, setIsRole] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [roleIdList, setRoleIdList] = useState([]);

    useEffect(() => {
        let breadcrumb = {};
        let breadcrumbList = Util.getBreadcrumb(outletContext, main.menuList, "/user/index");
        if (props.action == "save") {
            breadcrumb = {
                name: outletContext.intl.formatMessage({id: "global.new"}) + outletContext.intl.formatMessage({id: "user"}).toLowerCase(),
            };
        } else if (props.action == "update") {
            breadcrumb = {
                name: outletContext.intl.formatMessage({id: "global.modify"}) + outletContext.intl.formatMessage({id: "user"}).toLowerCase(),
            };

            handleLoadUser();

            setIsRefresh(true);
        }
        setBreadcrumb(breadcrumb);
        setBreadcrumbList(breadcrumbList.concat([breadcrumb]));

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        setErrorTitle("");
        setErrorList([]);

        return () => {};
    }, [localStorage.getItem("locale")]);

    const handleLoadUser = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.request(outletContext, {
            url: process.env.API_URL + "/user/backend/website/v1/item",
            controller: controller,
            data: {
                userId: props.userId,
            },
            success: (response) => {
                form.setFieldsValue(response.data);

                setDepartmentList(response.data.departmentList);
                setRoleList(response.data.roleList);

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleSubmitUser = () => {
        if (props.action == "save") {
            handleSaveUser();
        } else if (props.action == "update") {
            handleUpdateUser();
        }
    };

    const handleSaveUser = () => {
        if (request.current) {
            return;
        }

        form.validate()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                data.userPassword = Util.md5(data.userPassword);

                let departmentIdList = [];
                for (let i = 0; i < departmentList.length; i++) {
                    departmentIdList.push(departmentList[i].departmentId);
                }
                data.departmentIdList = departmentIdList;

                let roleIdList = [];
                for (let i = 0; i < roleList.length; i++) {
                    roleIdList.push(roleList[i].roleId);
                }
                data.roleIdList = roleIdList;

                Util.request(outletContext, {
                    url: process.env.API_URL + "/user/backend/website/v1/save",
                    controller: controller,
                    data: data,
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        props.onSubmit();
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV == "development") {
                    console.log(error);
                }
            });
    };

    const handleUpdateUser = () => {
        if (request.current) {
            return;
        }

        form.validate()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                data.userId = props.userId;

                let departmentIdList = [];
                for (let i = 0; i < departmentList.length; i++) {
                    departmentIdList.push(departmentList[i].departmentId);
                }
                data.departmentIdList = departmentIdList;

                let roleIdList = [];
                for (let i = 0; i < roleList.length; i++) {
                    roleIdList.push(roleList[i].roleId);
                }
                data.roleIdList = roleIdList;

                Util.request(outletContext, {
                    url: process.env.API_URL + "/user/backend/website/v1/update",
                    controller: controller,
                    data: data,
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        props.onSubmit();
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV == "development") {
                    console.log(error);
                }
            });
    };

    return (
        <React.Fragment>
            <Modal
                style={{top: "100px", width: main.width - 300 <= 1024 ? main.width + "px" : main.width - 300 + "px"}}
                title={
                    <React.Fragment>
                        <div className="arco-modal-title-text">{breadcrumb.name}</div>
                        <Space>
                            {main.permissionList.indexOf(Util.md5("/user/backend/website/v1/item")) > -1 && isRefresh ? (
                                <Button
                                    className="arco-modal-title-button"
                                    type="text"
                                    icon={<IconRefresh className="navigation_icon" />}
                                    onClick={() => {
                                        handleLoadUser();
                                    }}
                                ></Button>
                            ) : null}
                            <Button
                                className="arco-modal-title-button"
                                type="text"
                                icon={<IconClose className="navigation_icon" />}
                                onClick={() => {
                                    props.onClose();
                                }}
                            ></Button>
                        </Space>
                    </React.Fragment>
                }
                visible={props.visible}
                focusLock={false}
                alignCenter={false}
                maskClosable={false}
                closeIcon={false}
                footer={
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{flex: 1, marginRight: "8px"}}></div>
                        <Space>
                            <Button
                                icon={<IconCloseCircle />}
                                onClick={() => {
                                    props.onClose();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.close"})}
                            </Button>
                            {main.permissionList.indexOf(Util.md5("/user/backend/website/v1/save")) > -1 && props.action === "save" ? (
                                <Button
                                    type="primary"
                                    icon={<IconSave />}
                                    loading={isLoad}
                                    onClick={() => {
                                        handleSubmitUser();
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.save"})}
                                </Button>
                            ) : null}
                            {main.permissionList.indexOf(Util.md5("/user/backend/website/v1/update")) > -1 && props.action === "update" ? (
                                <Button
                                    type="primary"
                                    icon={<IconSave />}
                                    loading={isLoad}
                                    onClick={() => {
                                        handleSubmitUser();
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.update"})}
                                </Button>
                            ) : null}
                        </Space>
                    </div>
                }
            >
                <Scrollbars
                    autoHeight
                    autoHeightMax={main.height - 300}
                    renderView={({style, ...props}) => {
                        style.overflow = isLoad ? "hidden" : "auto";

                        return <div className="scrollbar" style={style} {...props} />;
                    }}
                >
                    <div style={{width: main.width - 300 <= 1024 ? main.width + "px" : main.width - 300 + "px"}}>
                        <Breadcrumb className="breadcrumb">
                            {breadcrumbList.map((breadcrumb, breadcrumbIndex) => {
                                return <Breadcrumb.Item key={breadcrumbIndex}>{breadcrumb.name}</Breadcrumb.Item>;
                            })}
                        </Breadcrumb>
                        <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                        <Form form={form} autoComplete="off" layout="vertical">
                            <Card className="card" title={outletContext.intl.formatMessage({id: "global.basicInformation"})} bordered={false}>
                                <Grid.Row gutter={20}>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "user.userName"})}
                                            field="userName"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                                {
                                                    type: "string",
                                                    minLength: 0,
                                                    maxLength: 50,
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                allowClear
                                                onPressEnter={() => {
                                                    handleSubmitUser();
                                                }}
                                            />
                                        </Form.Item>
                                    </Grid.Col>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "user.userAccount"})}
                                            field="userAccount"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                                {
                                                    type: "string",
                                                    minLength: 0,
                                                    maxLength: 50,
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                allowClear
                                                onPressEnter={() => {
                                                    handleSubmitUser();
                                                }}
                                            />
                                        </Form.Item>
                                    </Grid.Col>
                                    {props.action == "save" ? (
                                        <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                            <Form.Item
                                                label={outletContext.intl.formatMessage({id: "user.userPassword"})}
                                                field="userPassword"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                    {
                                                        type: "string",
                                                        minLength: 0,
                                                        maxLength: 50,
                                                    },
                                                ]}
                                            >
                                                <Input.Password
                                                    placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                    allowClear
                                                    onPressEnter={() => {
                                                        handleSubmitUser();
                                                    }}
                                                />
                                            </Form.Item>
                                        </Grid.Col>
                                    ) : null}
                                    <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "user.userType"})}
                                            field="userType"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseSelect"})}
                                                allowClear
                                                onChange={(event) => {}}
                                            >
                                                <Select.Option value={0}>管理员</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Grid.Col>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "user.userCode"})}
                                            field="userCode"
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                                {
                                                    type: "string",
                                                    minLength: 0,
                                                    maxLength: 50,
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                allowClear
                                                onPressEnter={() => {
                                                    handleSubmitUser();
                                                }}
                                            />
                                        </Form.Item>
                                    </Grid.Col>
                                </Grid.Row>
                            </Card>
                            <Card className="card" title={outletContext.intl.formatMessage({id: "user.userDepartment"})} bordered={false}>
                                <div className="card_table_action">
                                    <Space className="action_detail" wrap>
                                        <Button
                                            icon={<IconCheckCircle />}
                                            loading={isLoad}
                                            onClick={() => {
                                                setIsDepartment(true);
                                            }}
                                        >
                                            {outletContext.intl.formatMessage({id: "global.select"})}
                                        </Button>
                                        <Button
                                            icon={<IconDelete />}
                                            loading={isLoad}
                                            onClick={() => {
                                                for (let i = 0; i < departmentIdList.length; i++) {
                                                    for (let j = 0; j < departmentList.length; j++) {
                                                        if (departmentList[j].departmentId == departmentIdList[i]) {
                                                            departmentList.splice(j, 1);
                                                            break;
                                                        }
                                                    }
                                                }
                                                setDepartmentIdList([]);
                                                setDepartmentList([...departmentList]);
                                            }}
                                        >
                                            {outletContext.intl.formatMessage({id: "global.remove"})}
                                        </Button>
                                    </Space>
                                    <Table
                                        className="table"
                                        columns={[
                                            {
                                                dataIndex: "departmentName",
                                                title: outletContext.intl.formatMessage({id: "department.departmentName"}),
                                            },
                                        ]}
                                        rowKey="departmentId"
                                        data={departmentList}
                                        rowSelection={{
                                            type: "checkbox",
                                            columnWidth: 50,
                                            selectedRowKeys: departmentIdList,
                                            onChange: (selectedRowKeys) => {
                                                setDepartmentIdList(selectedRowKeys);
                                            },
                                        }}
                                        scroll={{x: 50 + 1 * 150}}
                                        border={false}
                                        pagination={false}
                                    />
                                </div>
                            </Card>
                            <Card className="card" title={outletContext.intl.formatMessage({id: "user.userRole"})} bordered={false}>
                                <div className="card_table_action">
                                    <Space className="action_detail" wrap>
                                        <Button
                                            icon={<IconCheckCircle />}
                                            loading={isLoad}
                                            onClick={() => {
                                                setIsRole(true);
                                            }}
                                        >
                                            {outletContext.intl.formatMessage({id: "global.select"})}
                                        </Button>
                                        <Button
                                            icon={<IconDelete />}
                                            loading={isLoad}
                                            onClick={() => {
                                                for (let i = 0; i < roleIdList.length; i++) {
                                                    for (let j = 0; j < roleList.length; j++) {
                                                        if (roleList[j].roleId == roleIdList[i]) {
                                                            roleList.splice(j, 1);
                                                            break;
                                                        }
                                                    }
                                                }
                                                setRoleIdList([]);
                                                setRoleList([...roleList]);
                                            }}
                                        >
                                            {outletContext.intl.formatMessage({id: "global.remove"})}
                                        </Button>
                                    </Space>
                                    <Table
                                        className="table"
                                        columns={[
                                            {
                                                dataIndex: "roleName",
                                                title: outletContext.intl.formatMessage({id: "role.roleName"}),
                                            },
                                            {
                                                dataIndex: "roleCode",
                                                title: outletContext.intl.formatMessage({id: "role.roleCode"}),
                                            },
                                        ]}
                                        rowKey="roleId"
                                        data={roleList}
                                        rowSelection={{
                                            type: "checkbox",
                                            columnWidth: 50,
                                            selectedRowKeys: roleIdList,
                                            onChange: (selectedRowKeys) => {
                                                setRoleIdList(selectedRowKeys);
                                            },
                                        }}
                                        scroll={{x: 50 + 2 * 150}}
                                        border={false}
                                        pagination={false}
                                    />
                                </div>
                            </Card>
                        </Form>
                        <div className="card_bottom"></div>
                    </div>
                </Scrollbars>
                {isLoad ? <Loading /> : null}
            </Modal>
            {isDepartment ? (
                <DepartmentSelect
                    visible={isDepartment}
                    onClose={() => {
                        setIsDepartment(false);
                    }}
                    onSelect={(selectedList) => {
                        for (let i = 0; i < selectedList.length; i++) {
                            let selected = selectedList[i];
                            let isExist = false;
                            for (let j = 0; j < departmentList.length; j++) {
                                if (departmentList[j].departmentId == selected.departmentId) {
                                    departmentList[j] = selected;
                                    isExist = true;
                                    break;
                                }
                            }
                            if (!isExist) {
                                departmentList.push(selected);
                            }
                        }

                        setIsDepartment(false);
                        setDepartmentList([...departmentList]);
                    }}
                />
            ) : null}
            {isRole ? (
                <RoleSelect
                    visible={isRole}
                    onClose={() => {
                        setIsRole(false);
                    }}
                    onSelect={(selectedList) => {
                        for (let i = 0; i < selectedList.length; i++) {
                            let selected = selectedList[i];
                            let isExist = false;
                            for (let j = 0; j < roleList.length; j++) {
                                if (roleList[j].roleId == selected.roleId) {
                                    roleList[j] = selected;
                                    isExist = true;
                                    break;
                                }
                            }
                            if (!isExist) {
                                roleList.push(selected);
                            }
                        }

                        setIsRole(false);
                        setRoleList([...roleList]);
                    }}
                />
            ) : null}
        </React.Fragment>
    );
};

UserDetail.propTypes = {
    visible: PropTypes.bool.isRequired,
    action: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default Util.withRouter(UserDetail);
