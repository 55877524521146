import React, {useState, useRef, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {Message, Modal, Upload, Input, Button, Image, Spin} from "@arco-design/web-react";
import {IconUpload, IconEye, IconDelete} from "@arco-design/web-react/icon";
import {useSortable} from "use-sortablejs";
import PropTypes from "prop-types";

import Util from "../../js/util";
import {Space} from "@arco-design/web-react";

let headers = {
    Authorization: localStorage.getItem("authorization"),
    Locale: localStorage.getItem("locale"),
};
if (process.env.TENANT_ID) {
    headers.Tenant = process.env.TENANT_ID;
}

const FileIndex = ({
    uploadUrl = process.env.API_URL + process.env.UPLOAD_PATH,
    previewUrl = process.env.FILE_URL,
    disabled = false,
    placeholder = "",
    onPressEnter = () => {},
    ...props
}) => {
    const outletContext = useOutletContext();
    const controller = new AbortController();
    const uploadRef = useRef(false);
    const onChangeRef = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    const [previewIndex, setPreviewIndex] = useState(-1);
    const [previewList, setPreviewList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const {getRootProps, getItemProps} = useSortable({
        setItems: (setFun) => {
            const newItems = setFun(fileList);
            let value = [];
            for (let i = 0; i < newItems.length; i++) {
                try {
                    value.push(newItems[i].url);
                } catch (error) {}
            }
            props.onChange(value);
            return newItems;
        },
        options: {
            animation: 150,
        },
    });

    useEffect(() => {
        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        if (onChangeRef.current == true) {
            return;
        }

        if (props.type == "image") {
            if (props.limit == 1) {
                if (props.value) {
                    let fileList = [];
                    fileList.push({
                        uid: Util.getId(),
                        url: props.value,
                        status: "done",
                    });
                    setFileList(fileList);
                } else {
                    setFileList([]);
                }
            } else {
                if (props.value) {
                    let fileList = [];
                    for (let i = 0; i < props.value.length; i++) {
                        fileList.push({
                            uid: Util.getId(),
                            url: props.value[i],
                            status: "done",
                        });
                    }
                    setFileList(fileList);
                } else {
                    setFileList([]);
                }
            }
        }
    }, [props.value]);

    useEffect(() => {
        headers.Authorization = localStorage.getItem("authorization");
    }, [localStorage.getItem("authorization")]);

    return (
        <React.Fragment>
            {props.type == "image" ? (
                <div className="file_list" {...getRootProps()}>
                    {fileList.map((file, fileIndex) => {
                        return (
                            <div
                                key={file.uid}
                                style={{
                                    width: "82px",
                                    height: "82px",
                                }}
                                {...getItemProps(file)}
                            >
                                {file.status == "init" || file.status == "uploading" ? (
                                    <div className="file_item">
                                        <Spin loading>
                                            <Image width={82} src={URL.createObjectURL(file.originFile)} alt="" preview={false} />
                                        </Spin>
                                    </div>
                                ) : null}
                                {file.status == "done" ? (
                                    <div className="file_item_done">
                                        <Image width={82} src={previewUrl + file.url} alt="" preview={false} />
                                        <React.Fragment>
                                            <div className="file_item_mask"></div>
                                            <div className="file_item_button">
                                                <IconEye
                                                    className="file_item_icon"
                                                    onClick={() => {
                                                        let previewList = [];
                                                        for (let i = 0; i < fileList.length; i++) {
                                                            if (fileList[i].status == "done") {
                                                                previewList.push(previewUrl + fileList[i].url);
                                                            }
                                                        }
                                                        setIsPreview(true);
                                                        setPreviewIndex(fileIndex);
                                                        setPreviewList(previewList);
                                                    }}
                                                />
                                                <IconDelete
                                                    className="file_item_icon"
                                                    onClick={() => {
                                                        Modal.confirm({
                                                            title: outletContext.intl.formatMessage({id: "global.deleteConfirmTitle"}),
                                                            content: outletContext.intl.formatMessage({id: "global.deleteConfirmContent"}),
                                                            okButtonProps: {
                                                                status: "danger",
                                                            },
                                                            onOk: () => {
                                                                if (props.limit == 1) {
                                                                    props.onChange("");
                                                                } else {
                                                                    let value = [];
                                                                    fileList.splice(fileIndex, 1);
                                                                    for (let i = 0; i < fileList.length; i++) {
                                                                        try {
                                                                            value.push(fileList[i].url);
                                                                        } catch (error) {}
                                                                    }
                                                                    props.onChange(value);
                                                                }
                                                            },
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </React.Fragment>
                                    </div>
                                ) : null}
                                {file.status == "error" ? (
                                    <div className="file_item_error">
                                        <Image width={82} src={URL.createObjectURL(file.originFile)} alt="" preview={false} />
                                        <div className="file_item_retry_mask"></div>
                                        <div className="file_item_retry_text">
                                            <Button
                                                size="mini"
                                                type="primary"
                                                status="danger"
                                                onClick={() => {
                                                    props.onReupload();
                                                }}
                                            >
                                                点击重试
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                    <Upload
                        ref={uploadRef}
                        action={uploadUrl}
                        accept=".png,.jpg,.jpeg"
                        disabled={disabled}
                        listType="picture-card"
                        multiple={props.limit > 1}
                        imagePreview
                        headers={headers}
                        limit={props.limit}
                        fileList={fileList}
                        showUploadList={false}
                        onChange={(fileList, file) => {
                            onChangeRef.current = true;
                            setTimeout(() => {
                                onChangeRef.current = false;
                            }, 500);

                            if (file.status == "uploading") {
                            } else if (file.status == "done") {
                                if (file.response) {
                                    if (file.response.code == 0) {
                                        for (let i = 0; i < fileList.length; i++) {
                                            if (fileList[i].uid == file.uid) {
                                                fileList[i].url = file.response.data.filePath;
                                                break;
                                            }
                                        }

                                        if (props.limit == 1) {
                                            props.onChange(fileList[0].url);
                                        } else {
                                            let value = [];
                                            for (let i = 0; i < fileList.length; i++) {
                                                try {
                                                    value.push(fileList[i].url);
                                                } catch (error) {}
                                            }
                                            props.onChange(value);
                                        }
                                    } else {
                                        for (let i = 0; i < fileList.length; i++) {
                                            if (fileList[i].uid == file.uid) {
                                                fileList.splice(i, 1);
                                                break;
                                            }
                                        }
                                    }
                                }
                            } else if (file.status == "error") {
                            }

                            setFileList(fileList);
                        }}
                        onReupload={(file) => {}}
                        onRemove={(file, fileList) => {
                            if (props.type == "image") {
                                if (props.limit == 1) {
                                    props.onChange("");
                                } else {
                                    let value = [];
                                    for (let i = 0; i < fileList.length; i++) {
                                        value.push(fileList[i].url);
                                    }
                                    props.onChange(value);
                                }
                            }
                        }}
                        renderUploadList={(filesList, props) => {}}
                    />
                </div>
            ) : null}
            {props.type == "video" ? (
                <Input
                    placeholder={placeholder}
                    allowClear
                    value={props.value}
                    onChange={(event) => {
                        props.onChange(event);
                    }}
                    onPressEnter={() => {
                        onPressEnter();
                    }}
                    addAfter={
                        <Upload
                            action={uploadUrl}
                            accept=".mp4"
                            disabled={disabled}
                            multiple={props.limit > 1}
                            headers={headers}
                            fileList={fileList}
                            showUploadList={false}
                            onChange={(fileList, file) => {
                                console.log(file);
                                if (file.status == "uploading") {
                                    setIsLoad(true);
                                } else if (file.status == "done") {
                                    if (file.response) {
                                        if (file.response.code == 0) {
                                            props.onChange(file.response.data.filePath);
                                        } else {
                                            Message.error({
                                                content: file.response.message,
                                            });
                                        }
                                    }

                                    setIsLoad(false);
                                } else if (file.status == "error") {
                                    setIsLoad(false);

                                    Message.error({
                                        content: outletContext.intl.formatMessage({id: "global.errorMessage"}),
                                    });
                                }

                                setFileList(fileList);
                            }}
                        >
                            <Button type="secondary" icon={<IconUpload />} loading={isLoad}>
                                {outletContext.intl.formatMessage({id: "global.upload"})}
                            </Button>
                        </Upload>
                    }
                />
            ) : null}
            {props.type == "file" ? (
                <Input
                    value={props.value}
                    placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                    allowClear
                    onPressEnter={() => {
                        onPressEnter();
                    }}
                    onChange={(event) => {
                        if (props.onChange) {
                            props.onChange(event);
                        }
                    }}
                    addAfter={
                        <Upload
                            action={uploadUrl}
                            accept={props.accept}
                            disabled={disabled}
                            multiple={false}
                            headers={headers}
                            fileList={fileList}
                            showUploadList={false}
                            onChange={(fileList, file) => {
                                if (file.status == "uploading") {
                                    setIsLoad(true);
                                } else if (file.status == "done") {
                                    if (file.response) {
                                        if (file.response.code == 0) {
                                            props.onChange(file.response.data.filePath);
                                        } else {
                                            Message.error({
                                                content: file.response.message,
                                            });
                                        }
                                    }

                                    setIsLoad(false);
                                } else if (file.status == "error") {
                                    setIsLoad(false);

                                    Message.error({
                                        content: outletContext.intl.formatMessage({id: "global.errorMessage"}),
                                    });
                                }

                                setFileList(fileList);
                            }}
                        >
                            <Button type="secondary" icon={<IconUpload />} loading={isLoad}>
                                {outletContext.intl.formatMessage({id: "global.upload"})}
                            </Button>
                        </Upload>
                    }
                />
            ) : // <Upload
            //     multiple
            //     accept={props.accept}
            //     action="/"
            //     onDrop={(e) => {
            //         let uploadFile = e.dataTransfer.files[0];
            //         if (isAcceptFile(uploadFile, "image/*")) {
            //             return;
            //         } else {
            //             Message.info("不接受的文件类型，请重新上传指定文件类型~");
            //         }
            //     }}
            // />
            null}
            {isPreview ? (
                <Image.PreviewGroup
                    visible={isPreview}
                    defaultCurrent={previewIndex}
                    srcList={previewList}
                    onVisibleChange={(visible) => {
                        setIsPreview(visible);
                    }}
                />
            ) : null}
        </React.Fragment>
    );
};

FileIndex.propTypes = {
    type: PropTypes.string.isRequired,
    uploadUrl: PropTypes.string,
    previewUrl: PropTypes.string,
    limit: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onPressEnter: PropTypes.func,
};

export default Util.withRouter(FileIndex);
