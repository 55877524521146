import React, {useEffect} from "react";
import {Spin} from "@arco-design/web-react";

const LoadingIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    useEffect(() => {}, []);

    return (
        <React.Fragment>
            <div className="loading">
                <div className="loading_mask"></div>
                <Spin className="loading_icon" size={30}></Spin>
            </div>
        </React.Fragment>
    );
};

LoadingIndex.propTypes = {};

export default LoadingIndex;
