import ReconnectingWebSocket from "reconnecting-websocket";
import Protobufjs from "protobufjs";

let websocket = null;
let isConnected = false;
const root = Protobufjs.Root.fromJSON({
    nested: {
        Request: {
            fields: {
                type: {
                    type: "int32",
                    id: 2,
                },
                data: {
                    type: "string",
                    id: 3,
                },
            },
        },
        Response: {
            fields: {
                code: {
                    type: "int32",
                    id: 1,
                },
                type: {
                    type: "int32",
                    id: 2,
                },
                data: {
                    type: "string",
                    id: 3,
                },
                message: {
                    type: "string",
                    id: 4,
                },
            },
        },
    },
});
const Request = root.lookupType("Request");
const Response = root.lookupType("Response");

export default {
    connect: () => {
        if (isConnected) {
            return;
        }

        // if (typeof process.env.WEBSOCKET_URL == "undefined") {
        //     return;
        // }

        // websocket = new ReconnectingWebSocket(process.env.WEBSOCKET_URL, null, {
        //     headers: {
        //         Authorization: localStorage.getItem("authorization"),
        //     },
        // });

        // websocket.onopen = (event) => {
        //     console.log("onopen");

        //     const message = Request.create({
        //         type: 1,
        //         data: "{}",
        //     });
        //     const buffer = Request.encode(message).finish();
        //     websocket.send(buffer);
        // };

        // websocket.onclose = (event) => {
        //     console.log("onclose");
        // };

        // websocket.onmessage = (event) => {
        //     console.log("onmessage");

        //     const reader = new FileReader();
        //     reader.readAsArrayBuffer(event.data);
        //     reader.onload = function (e) {
        //         const buf = new Uint8Array(reader.result);
        //         const message = Response.decode(buf);
        //         const object = Response.toObject(message);
        //         console.log(object);
        //     };
        // };
    },
};
