import React, {useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {Space, Tag} from "@arco-design/web-react";
import PropTypes from "prop-types";

const SortIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const outletContext = useOutletContext();
    useEffect(() => {}, []);

    return (
        <React.Fragment>
            {props.sortList.length > 0 ? (
                <div className="tag">
                    <Space wrap>
                        {props.sortList.map((sort, sortIndex) => {
                            return (
                                <Tag
                                    key={sort.field}
                                    checkable
                                    checked
                                    onCheck={() => {
                                        let sortList = [...props.sortList];
                                        if (sort.direction == "ascend") {
                                            sortList[sortIndex].direction = "descend";
                                        } else {
                                            sortList[sortIndex].direction = "ascend";
                                        }
                                        props.setSortList([...sortList]);
                                    }}
                                    closable
                                    onClose={(event) => {
                                        event.stopPropagation();

                                        let sortList = [...props.sortList];
                                        sortList.splice(sortIndex, 1);
                                        props.setSortList([...sortList]);
                                    }}
                                >
                                    {sort.title + ": " + outletContext.intl.formatMessage({id: "global." + sort.direction})}
                                </Tag>
                            );
                        })}
                    </Space>
                </div>
            ) : null}
        </React.Fragment>
    );
};

SortIndex.propTypes = {
    sortList: PropTypes.array.isRequired,
    setSortList: PropTypes.func.isRequired,
};

export default SortIndex;
