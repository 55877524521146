import React, {useState, useRef, useEffect} from "react";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {
    ClassicEditor,
    AccessibilityHelp,
    Alignment,
    Autoformat,
    AutoImage,
    Autosave,
    BlockQuote,
    Bold,
    CloudServices,
    Code,
    CodeBlock,
    Essentials,
    FindAndReplace,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    FullPage,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HorizontalLine,
    HtmlComment,
    HtmlEmbed,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    PageBreak,
    Paragraph,
    RemoveFormat,
    SelectAll,
    ShowBlocks,
    SimpleUploadAdapter,
    SourceEditing,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    TodoList,
    Underline,
    Undo,
} from "ckeditor5";
import translations from "ckeditor5/translations/zh-cn.js";
import axios from "axios";

import "ckeditor5/ckeditor5.css";

import Util from "../../js/util";

class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file.then((file) => {
            return new Promise((resolve, reject) => {
                const data = new FormData();
                data.append("file", file);
                axios
                    .post(process.env.API_URL + process.env.UPLOAD_PATH, data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: localStorage.getItem("authorization"),
                        },
                    })
                    .then((response) => {
                        if (response.data.code == 0) {
                            resolve({
                                default: process.env.FILE_URL + response.data.data.filePath,
                            });
                        } else {
                            reject(response.data.message);
                        }
                    })
                    .catch((response) => {
                        reject("Upload failed");
                    });
            });
        });
    }

    abort() {}
}

const EditorIndex = ({mobile = true, ...props}) => {
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <React.Fragment>
            <div className={mobile ? "editor_mobile" : null}>
                <CKEditor
                    editor={ClassicEditor}
                    config={{
                        StyleConfig: {
                            width: "450px",
                        },
                        toolbar: {
                            items: [
                                "undo",
                                "redo",
                                "|",
                                "sourceEditing",
                                "showBlocks",
                                "findAndReplace",
                                "|",
                                "heading",
                                "|",
                                "fontSize",
                                "fontFamily",
                                "fontColor",
                                "fontBackgroundColor",
                                "|",
                                "bold",
                                "italic",
                                "underline",
                                "strikethrough",
                                "subscript",
                                "superscript",
                                "code",
                                "removeFormat",
                                "|",
                                "specialCharacters",
                                "horizontalLine",
                                "pageBreak",
                                "link",
                                "insertImage",
                                "insertTable",
                                "highlight",
                                "blockQuote",
                                "codeBlock",
                                "htmlEmbed",
                                "|",
                                "alignment",
                                "|",
                                "bulletedList",
                                "numberedList",
                                "todoList",
                                "outdent",
                                "indent",
                            ],
                            shouldNotGroupWhenFull: false,
                        },
                        plugins: [
                            AccessibilityHelp,
                            Alignment,
                            Autoformat,
                            AutoImage,
                            Autosave,
                            BlockQuote,
                            Bold,
                            CloudServices,
                            Code,
                            CodeBlock,
                            Essentials,
                            FindAndReplace,
                            FontBackgroundColor,
                            FontColor,
                            FontFamily,
                            FontSize,
                            FullPage,
                            GeneralHtmlSupport,
                            Heading,
                            Highlight,
                            HorizontalLine,
                            HtmlComment,
                            HtmlEmbed,
                            ImageBlock,
                            ImageCaption,
                            ImageInline,
                            ImageInsert,
                            ImageInsertViaUrl,
                            ImageResize,
                            ImageStyle,
                            ImageTextAlternative,
                            ImageToolbar,
                            ImageUpload,
                            Indent,
                            IndentBlock,
                            Italic,
                            Link,
                            LinkImage,
                            List,
                            ListProperties,
                            PageBreak,
                            Paragraph,
                            RemoveFormat,
                            SelectAll,
                            ShowBlocks,
                            SimpleUploadAdapter,
                            SourceEditing,
                            SpecialCharacters,
                            SpecialCharactersArrows,
                            SpecialCharactersCurrency,
                            SpecialCharactersEssentials,
                            SpecialCharactersLatin,
                            SpecialCharactersMathematical,
                            SpecialCharactersText,
                            Strikethrough,
                            Subscript,
                            Superscript,
                            Table,
                            TableCaption,
                            TableCellProperties,
                            TableColumnResize,
                            TableProperties,
                            TableToolbar,
                            TextTransformation,
                            TodoList,
                            Underline,
                            Undo,
                        ],
                        fontFamily: {
                            supportAllValues: true,
                        },
                        fontSize: {
                            options: [10, 12, 14, "default", 18, 20, 22],
                            supportAllValues: true,
                        },
                        heading: {
                            options: [
                                {
                                    model: "paragraph",
                                    title: "Paragraph",
                                    class: "ck-heading_paragraph",
                                },
                                {
                                    model: "heading1",
                                    view: "h1",
                                    title: "Heading 1",
                                    class: "ck-heading_heading1",
                                },
                                {
                                    model: "heading2",
                                    view: "h2",
                                    title: "Heading 2",
                                    class: "ck-heading_heading2",
                                },
                                {
                                    model: "heading3",
                                    view: "h3",
                                    title: "Heading 3",
                                    class: "ck-heading_heading3",
                                },
                                {
                                    model: "heading4",
                                    view: "h4",
                                    title: "Heading 4",
                                    class: "ck-heading_heading4",
                                },
                                {
                                    model: "heading5",
                                    view: "h5",
                                    title: "Heading 5",
                                    class: "ck-heading_heading5",
                                },
                                {
                                    model: "heading6",
                                    view: "h6",
                                    title: "Heading 6",
                                    class: "ck-heading_heading6",
                                },
                            ],
                        },
                        htmlSupport: {
                            allow: [
                                {
                                    name: /^.*$/,
                                    styles: true,
                                    attributes: true,
                                    classes: true,
                                },
                            ],
                        },
                        image: {
                            toolbar: [
                                "toggleImageCaption",
                                "imageTextAlternative",
                                "|",
                                "imageStyle:inline",
                                "imageStyle:wrapText",
                                "imageStyle:breakText",
                                "|",
                                "resizeImage",
                            ],
                        },
                        language: "zh-cn",
                        link: {
                            addTargetToExternalLinks: true,
                            defaultProtocol: "https://",
                            decorators: {
                                toggleDownloadable: {
                                    mode: "manual",
                                    label: "Downloadable",
                                    attributes: {
                                        download: "file",
                                    },
                                },
                            },
                        },
                        list: {
                            properties: {
                                styles: true,
                                startIndex: true,
                                reversed: true,
                            },
                        },
                        placeholder: "Type or paste your content here!",
                        table: {
                            contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"],
                        },
                        translations: [translations],
                    }}
                    data={props.value}
                    onReady={(editor) => {
                        editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());

                        editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
                            return new UploadAdapter(loader);
                        };
                    }}
                    onError={(error, {willEditorRestart}) => {
                        if (willEditorRestart) {
                            this.editor.ui.view.toolbar.element.remove();
                        }
                    }}
                    onChange={(event, editor) => {
                        props.onChange(editor.getData());
                    }}
                />
            </div>
        </React.Fragment>
    );
};

EditorIndex.propTypes = {};

export default Util.withRouter(EditorIndex);
