const initialState = {
    locale: "zh",
    theme: "",
    width: 0,
    height: 0,
    isMobile: false,
    isLogin: false,
    userName: "",
    menuList: [],
    permissionList: [],
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case "main":
            return Object.assign({}, state, action.data);

        default:
            return state;
    }
}

const data = {
    name: "main",
    reducer: reducer,
};

export default data;
