import { PREFIX } from './base';

export const DEFAULT_MEASURE_CANVAS_ID = `${PREFIX}_MEASURE_CANVAS_ID`;

export const DEFAULT_DATA_INDEX = `${PREFIX}_DEFAULT_DATA_INDEX`;

export const DEFAULT_DATA_KEY = `${PREFIX}_DEFAULT_DATA_KEY`;

export const DEFAULT_DATA_SERIES_FIELD = `${PREFIX}_DEFAULT_DATA_SERIES_FIELD`;

export const DEFAULT_SERIES_STYLE_NAME = `${PREFIX}_DEFAULT_SERIES_STYLE_NAME`;

export const STACK_FIELD_KEY = `${PREFIX}_STACK_KEY`;
export const STACK_FIELD_START = `${PREFIX}_STACK_START`;
export const STACK_FIELD_END = `${PREFIX}_STACK_END`;
export const STACK_FIELD_START_PERCENT = `${PREFIX}_STACK_START_PERCENT`;
export const STACK_FIELD_END_PERCENT = `${PREFIX}_STACK_END_PERCENT`;
export const STACK_FIELD_START_OffsetSilhouette = `${PREFIX}_STACK_START_OffsetSilhouette`;
export const STACK_FIELD_END_OffsetSilhouette = `${PREFIX}_STACK_END_OffsetSilhouette`;
export const STACK_FIELD_TOTAL = `${PREFIX}_STACK_TOTAL`;
export const STACK_FIELD_TOTAL_PERCENT = `${PREFIX}_STACK_TOTAL_PERCENT`;
export const STACK_FIELD_TOTAL_TOP = `${PREFIX}_STACK_TOTAL_TOP`;
export const STACK_FIELD_TOTAL_BOTTOM = `${PREFIX}_STACK_TOTAL_BOTTOM`;

export const MOSAIC_VALUE_START_PERCENT = `${PREFIX}_MOSAIC_VALUE_START_PERCENT`;
export const MOSAIC_VALUE_END_PERCENT = `${PREFIX}_MOSAIC_VALUE_END_PERCENT`;
export const MOSAIC_CAT_START_PERCENT = `${PREFIX}_MOSAIC_CAT_START_PERCENT`;
export const MOSAIC_CAT_END_PERCENT = `${PREFIX}_MOSAIC_CAT_END_PERCENT`;

export const SEGMENT_FIELD_START = `${PREFIX}_SEGMENT_START`;
export const SEGMENT_FIELD_END = `${PREFIX}_SEGMENT_END`;
