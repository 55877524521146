import React, {useState, useRef, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useSelector} from "react-redux";
import {Message, Modal, Card, Space, Button, Table} from "@arco-design/web-react";
import {IconDelete, IconCloseCircle} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

import Util from "../../js/util";

import Error from "../error";
import Loading from "../loading";

const SelectedIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [columnList, setColumnList] = useState([]);
    const [idList, setIdList] = useState([]);
    const [dataList, setDataList] = useState([]);

    useEffect(() => {
        if (props.visible) {
            handleLoadSelectedList();
        }
    }, [props.visible]);

    useEffect(() => {
        let columnList = JSON.parse(JSON.stringify(props.columnList));
        for (let i = 0; i < columnList.length; i++) {
            columnList[i].sorter = "";
            columnList[i].sortOrder = "";
        }
        setColumnList(columnList);
    }, [props.columnList]);

    const handleLoadSelectedList = () => {
        if (props.selectedIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        let data = {};
        data[props.selectedIdListKey] = props.selectedIdList;
        data.sortList = props.sortList;

        Util.request(outletContext, {
            url: props.url,
            controller: controller,
            data: data,
            success: (response) => {
                setDataList(response.data);

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    return (
        <React.Fragment>
            <Modal
                style={{top: "100px", width: main.width <= 520 ? "100%" : "520px"}}
                title={<div style={{textAlign: "left"}}>{outletContext.intl.formatMessage({id: "global.selectedData"})}</div>}
                visible={props.visible}
                focusLock={false}
                alignCenter={false}
                maskClosable={false}
                onCancel={() => {
                    props.onClose();
                }}
                footer={
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Button
                            icon={<IconDelete />}
                            disabled={idList.length == 0}
                            onClick={() => {
                                props.onRemove(idList);
                            }}
                        >
                            {outletContext.intl.formatMessage({id: "global.remove"})}
                        </Button>
                        <div style={{flex: 1, marginRight: "8px"}}></div>
                        <Space>
                            <Button
                                icon={<IconCloseCircle />}
                                onClick={() => {
                                    props.onClose();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.close"})}
                            </Button>
                        </Space>
                    </div>
                }
            >
                <Scrollbars
                    autoHeight
                    autoHeightMax={main.height - 300}
                    renderView={({style, ...props}) => {
                        return <div style={style} {...props} />;
                    }}
                >
                    <div className="card_top"></div>
                    <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                    <Card className="card" bordered={false}>
                        <div className="card_content">
                            <Table
                                className="table"
                                columns={columnList}
                                rowKey={props.rowKey}
                                data={dataList}
                                rowSelection={{
                                    type: "checkbox",
                                    columnWidth: 50,
                                    selectedRowKeys: idList,
                                    onChange: (selectedRowKeys) => {
                                        setIdList(selectedRowKeys);
                                    },
                                }}
                                scroll={columnList.length > 1 ? {x: 50 + columnList.length * 150} : {}}
                                border={false}
                                pagination={false}
                            />
                        </div>
                    </Card>
                    {isLoad ? <Loading /> : null}
                </Scrollbars>
            </Modal>
        </React.Fragment>
    );
};

SelectedIndex.propTypes = {
    visible: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
    selectedIdListKey: PropTypes.string.isRequired,
    selectedIdList: PropTypes.array.isRequired,
    sortList: PropTypes.array.isRequired,
    rowKey: PropTypes.string.isRequired,
    columnList: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default SelectedIndex;
