import React, {useState, useEffect} from "react";
import {useOutlet} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Drawer} from "@arco-design/web-react";
import {useOutletContext} from "react-router-dom";

import Util from "../../../../../nowui-common/js/util";

const Global = ({...props}) => {
    const outlet = useOutlet();
    const dispatch = useDispatch();
    const controller = new AbortController();
    const [isDrawer, setIsDrawer] = useState(false);

    useEffect(() => {
        let locale = localStorage.getItem("locale");
        if (locale) {
            dispatch({
                type: "main",
                data: {
                    locale: locale,
                },
            });
        }

        let theme = localStorage.getItem("theme");
        if (theme) {
            dispatch({
                type: "main",
                data: {
                    theme: theme,
                },
            });
        }

        return () => {
            controller.abort();
        };
    }, []);

    return (
        <React.Fragment>
            {outlet}
            <Drawer
                width={332}
                title={<span>Basic Information </span>}
                visible={isDrawer}
                onOk={() => {
                    setIsDrawer(false);
                }}
                onCancel={() => {
                    setIsDrawer(false);
                }}
            ></Drawer>
        </React.Fragment>
    );
};

Global.propTypes = {};

export default Util.withRouter(Global);
