import React from "react";
import {Result} from "@arco-design/web-react";
import {injectIntl} from "react-intl";

import Util from "../../js/util";

const NotFoundIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    return (
        <React.Fragment>
            <div className="notfound">
                <Result status="404" subTitle={props.intl.formatMessage({id: "global.notFound"})}></Result>
            </div>
        </React.Fragment>
    );
};

NotFoundIndex.propTypes = {};

export default Util.withRouter(injectIntl(NotFoundIndex));
