import React, {useState, useRef, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useSelector} from "react-redux";
import {Message, Modal, Space, Button, Card, Table} from "@arco-design/web-react";
import {IconCloseCircle} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

import Util from "../../js/util";

import Error from "../error";
import Loading from "../loading";

const ProcessIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [columnList, setColumnList] = useState([]);
    const [dataList, setDataList] = useState([]);
    const timer = useRef();

    useEffect(() => {
        let columnList = [
            {
                title: outletContext.intl.formatMessage({id: "global.operationRecord"}),
                render: (col, record, index) => (
                    <div>
                        {record.processStatus == 0 ? <div className="process_time">{record.processStatusLabel}</div> : null}
                        {record.processStatus == 1 ? (
                            <div
                                className="process_title"
                                onClick={() => {
                                    window.open(props.apiUrl + "/file/" + record.fileBucket + "/" + record.fileName);
                                }}
                            >
                                {record.originalName}
                            </div>
                        ) : null}
                        {record.processStatus == 2 ? <div className="process_time">{record.errorMessage}</div> : null}
                        {record.processStatus == 3 ? (
                            <div className="process_time">{outletContext.intl.formatMessage({id: "global.exportFailed"})}</div>
                        ) : null}
                        {record.processStatus == 1 ? (
                            <div className="process_time">
                                {outletContext.intl.formatMessage({id: "global.success"})}: {record.successCount}
                                {props.processType == 0 ? (
                                    <React.Fragment>
                                        ; {outletContext.intl.formatMessage({id: "global.error"})}: {record.errorCount} ;
                                    </React.Fragment>
                                ) : null}
                            </div>
                        ) : null}
                        <div className="process_time">{record.createTime}</div>
                    </div>
                ),
            },
        ];
        setColumnList(columnList);

        return () => {
            controller.abort();

            if (timer.current) {
                clearInterval(timer.current);
            }
        };
    }, []);

    useEffect(() => {
        if (props.visible) {
            handleLoadProcessList();
        }
    }, [props.visible]);

    const handleLoadProcessList = () => {
        if (timer.current) {
            clearInterval(timer.current);
        }

        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.request(outletContext, {
            url: props.apiUrl + "/process/backend/website/v1/list",
            controller: controller,
            data: {
                processModule: props.processModule,
                processType: props.processType,
            },
            success: (response) => {
                setDataList(response.data);

                let isWait = false;
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].processStatus == 0) {
                        isWait = true;
                        break;
                    }
                }
                if (isWait) {
                    timer.current = setInterval(() => {
                        handleLoadProcessList();
                    }, 2000);
                }

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    return (
        <React.Fragment>
            <Modal
                style={{top: "100px", width: main.width <= 520 ? "100%" : "520px"}}
                title={
                    <div style={{textAlign: "left"}}>
                        {props.processType == 0
                            ? outletContext.intl.formatMessage({id: "global.importHistory"})
                            : outletContext.intl.formatMessage({id: "global.exportHistory"})}
                    </div>
                }
                visible={props.visible}
                focusLock={false}
                alignCenter={false}
                maskClosable={false}
                onCancel={() => {
                    props.onClose();
                }}
                footer={
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{flex: 1}}></div>
                        <Space>
                            <Button
                                icon={<IconCloseCircle />}
                                onClick={() => {
                                    props.onClose();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.close"})}
                            </Button>
                        </Space>
                    </div>
                }
            >
                <Scrollbars
                    autoHeight
                    autoHeightMax={main.height - 300}
                    renderView={({style, ...props}) => {
                        return <div style={style} {...props} />;
                    }}
                >
                    <div className="card_top"></div>
                    <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                    <Card className="card" bordered={false}>
                        <div className="warning">
                            <div className="warning_item">
                                <div className="warning_circle"></div>
                                <div className="warning_text">{outletContext.intl.formatMessage({id: "global.exportHistoryWarning0"})}</div>
                            </div>
                        </div>
                    </Card>
                    <Card className="card" bordered={false}>
                        <div className="card_content">
                            <Table className="table" columns={columnList} rowKey="processId" data={dataList} border={false} pagination={false} />
                        </div>
                    </Card>
                    {/* {isLoad ? <Loading /> : null} */}
                </Scrollbars>
            </Modal>
        </React.Fragment>
    );
};

ProcessIndex.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    apiUrl: PropTypes.string.isRequired,
    processModule: PropTypes.string.isRequired,
    processType: PropTypes.number.isRequired,
};

export default ProcessIndex;
