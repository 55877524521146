import React, {useEffect} from "react";
import {Select} from "@arco-design/web-react";
import {
    IconAt,
    IconCloudDownload,
    IconCodeBlock,
    IconCodeSquare,
    IconCode,
    IconCustomerService,
    IconDownload,
    IconExport,
    IconEyeInvisible,
    IconEye,
    IconHeart,
    IconHistory,
    IconHome,
    IconImport,
    IconLaunch,
    IconList,
    IconMessageBanned,
    IconMessage,
    IconMoreVertical,
    IconMore,
    IconPoweroff,
    IconRefresh,
    IconReply,
    IconSave,
    IconScan,
    IconSearch,
    IconCheckCircle,
    IconSend,
    IconSettings,
    IconShareAlt,
    IconShareExternal,
    IconShareInternal,
    IconStar,
    IconSync,
    IconThumbDown,
    IconThumbUp,
    IconTranslate,
    IconUpload,
    IconVoice,
    IconApps,
    IconArchive,
    IconBook,
    IconBranch,
    IconBug,
    IconBulb,
    IconCalendarClock,
    IconCalendar,
    IconCamera,
    IconCloud,
    IconCommand,
    IconCommon,
    IconCompass,
    IconCopyright,
    IconDashboard,
    IconDesktop,
    IconDice,
    IconDragDotVertical,
    IconDragDot,
    IconDriveFile,
    IconEar,
    IconEmail,
    IconEmpty,
    IconExperiment,
    IconFileAudio,
    IconFileImage,
    IconFilePdf,
    IconFileVideo,
    IconFile,
    IconFire,
    IconFolderAdd,
    IconFolderDelete,
    IconFolder,
    IconGift,
    IconIdcard,
    IconImageClose,
    IconImage,
    IconInteraction,
    IconLanguage,
    IconLayout,
    IconLocation,
    IconLock,
    IconLoop,
    IconMan,
    IconMenu,
    IconMindMapping,
    IconMobile,
    IconMoon,
    IconMosaic,
    IconNav,
    IconNotificationClose,
    IconNotification,
    IconPalette,
    IconPen,
    IconPhone,
    IconPrinter,
    IconPublic,
    IconPushpin,
    IconQrcode,
    IconRobotAdd,
    IconRobot,
    IconSafe,
    IconSchedule,
    IconShake,
    IconSkin,
    IconStamp,
    IconStorage,
    IconSubscribeAdd,
    IconSubscribe,
    IconSubscribed,
    IconSun,
    IconTag,
    IconTags,
    IconThunderbolt,
    IconTool,
    IconTrophy,
    IconUnlock,
    IconUserAdd,
    IconUserGroup,
    IconUser,
    IconVideoCamera,
    IconWifi,
    IconWoman,
} from "@arco-design/web-react/icon";
import PropTypes from "prop-types";

const IconIndex = ({onChange = () => {}, ...props}) => {
    useEffect(() => {}, []);

    return (
        <React.Fragment>
            <Select
                allowClear
                showSearch
                placeholder={props.placeholder}
                value={props.value}
                onChange={(event) => {
                    onChange(event);
                }}
            >
                <Select.Option value="IconAt">
                    <IconAt />
                </Select.Option>
                <Select.Option value="IconCloudDownload">
                    <IconCloudDownload />
                </Select.Option>
                <Select.Option value="IconCodeBlock">
                    <IconCodeBlock />
                </Select.Option>
                <Select.Option value="IconCodeSquare">
                    <IconCodeSquare />
                </Select.Option>
                <Select.Option value="IconCode">
                    <IconCode />
                </Select.Option>
                <Select.Option value="IconCustomerService">
                    <IconCustomerService />
                </Select.Option>
                <Select.Option value="IconDownload">
                    <IconDownload />
                </Select.Option>
                <Select.Option value="IconExport">
                    <IconExport />
                </Select.Option>
                <Select.Option value="IconEyeInvisible">
                    <IconEyeInvisible />
                </Select.Option>
                <Select.Option value="IconEye">
                    <IconEye />
                </Select.Option>
                <Select.Option value="IconHeart">
                    <IconHeart />
                </Select.Option>
                <Select.Option value="IconHistory">
                    <IconHistory />
                </Select.Option>
                <Select.Option value="IconHome">
                    <IconHome />
                </Select.Option>
                <Select.Option value="IconImport">
                    <IconImport />
                </Select.Option>
                <Select.Option value="IconLaunch">
                    <IconLaunch />
                </Select.Option>
                <Select.Option value="IconList">
                    <IconList />
                </Select.Option>
                <Select.Option value="IconMessageBanned">
                    <IconMessageBanned />
                </Select.Option>
                <Select.Option value="IconMessage">
                    <IconMessage />
                </Select.Option>
                <Select.Option value="IconMoreVertical">
                    <IconMoreVertical />
                </Select.Option>
                <Select.Option value="IconMore">
                    <IconMore />
                </Select.Option>
                <Select.Option value="IconPoweroff">
                    <IconPoweroff />
                </Select.Option>
                <Select.Option value="IconRefresh">
                    <IconRefresh />
                </Select.Option>
                <Select.Option value="IconReply">
                    <IconReply />
                </Select.Option>
                <Select.Option value="IconSave">
                    <IconSave />
                </Select.Option>
                <Select.Option value="IconScan">
                    <IconScan />
                </Select.Option>
                <Select.Option value="IconSearch">
                    <IconSearch />
                </Select.Option>
                <Select.Option value="IconCheckCircle">
                    <IconCheckCircle />
                </Select.Option>
                <Select.Option value="IconSend">
                    <IconSend />
                </Select.Option>
                <Select.Option value="IconSettings">
                    <IconSettings />
                </Select.Option>
                <Select.Option value="IconShareAlt">
                    <IconShareAlt />
                </Select.Option>
                <Select.Option value="IconShareExternal">
                    <IconShareExternal />
                </Select.Option>
                <Select.Option value="IconShareInternal">
                    <IconShareInternal />
                </Select.Option>
                <Select.Option value="IconStar">
                    <IconStar />
                </Select.Option>
                <Select.Option value="IconSync">
                    <IconSync />
                </Select.Option>
                <Select.Option value="IconThumbDown">
                    <IconThumbDown />
                </Select.Option>
                <Select.Option value="IconThumbUp">
                    <IconThumbUp />
                </Select.Option>
                <Select.Option value="IconTranslate">
                    <IconTranslate />
                </Select.Option>
                <Select.Option value="IconUpload">
                    <IconUpload />
                </Select.Option>
                <Select.Option value="IconVoice">
                    <IconVoice />
                </Select.Option>
                <Select.Option value="IconApps">
                    <IconApps />
                </Select.Option>
                <Select.Option value="IconArchive">
                    <IconArchive />
                </Select.Option>
                <Select.Option value="IconBook">
                    <IconBook />
                </Select.Option>
                <Select.Option value="IconBranch">
                    <IconBranch />
                </Select.Option>
                <Select.Option value="IconBug">
                    <IconBug />
                </Select.Option>
                <Select.Option value="IconBulb">
                    <IconBulb />
                </Select.Option>
                <Select.Option value="IconCalendarClock">
                    <IconCalendarClock />
                </Select.Option>
                <Select.Option value="IconCalendar">
                    <IconCalendar />
                </Select.Option>
                <Select.Option value="IconCamera">
                    <IconCamera />
                </Select.Option>
                <Select.Option value="IconCloud">
                    <IconCloud />
                </Select.Option>
                <Select.Option value="IconCommand">
                    <IconCommand />
                </Select.Option>
                <Select.Option value="IconCommon">
                    <IconCommon />
                </Select.Option>
                <Select.Option value="IconCompass">
                    <IconCompass />
                </Select.Option>
                <Select.Option value="IconCopyright">
                    <IconCopyright />
                </Select.Option>
                <Select.Option value="IconDashboard">
                    <IconDashboard />
                </Select.Option>
                <Select.Option value="IconDesktop">
                    <IconDesktop />
                </Select.Option>
                <Select.Option value="IconDice">
                    <IconDice />
                </Select.Option>
                <Select.Option value="IconDragDotVertical">
                    <IconDragDotVertical />
                </Select.Option>
                <Select.Option value="IconDragDot">
                    <IconDragDot />
                </Select.Option>
                <Select.Option value="IconDriveFile">
                    <IconDriveFile />
                </Select.Option>
                <Select.Option value="IconEar">
                    <IconEar />
                </Select.Option>
                <Select.Option value="IconEmail">
                    <IconEmail />
                </Select.Option>
                <Select.Option value="IconEmpty">
                    <IconEmpty />
                </Select.Option>
                <Select.Option value="IconExperiment">
                    <IconExperiment />
                </Select.Option>
                <Select.Option value="IconFileAudio">
                    <IconFileAudio />
                </Select.Option>
                <Select.Option value="IconFileImage">
                    <IconFileImage />
                </Select.Option>
                <Select.Option value="IconFilePdf">
                    <IconFilePdf />
                </Select.Option>
                <Select.Option value="IconFileVideo">
                    <IconFileVideo />
                </Select.Option>
                <Select.Option value="IconFile">
                    <IconFile />
                </Select.Option>
                <Select.Option value="IconFire">
                    <IconFire />
                </Select.Option>
                <Select.Option value="IconFolderAdd">
                    <IconFolderAdd />
                </Select.Option>
                <Select.Option value="IconFolderDelete">
                    <IconFolderDelete />
                </Select.Option>
                <Select.Option value="IconFolder">
                    <IconFolder />
                </Select.Option>
                <Select.Option value="IconGift">
                    <IconGift />
                </Select.Option>
                <Select.Option value="IconIdcard">
                    <IconIdcard />
                </Select.Option>
                <Select.Option value="IconImageClose">
                    <IconImageClose />
                </Select.Option>
                <Select.Option value="IconImage">
                    <IconImage />
                </Select.Option>
                <Select.Option value="IconInteraction">
                    <IconInteraction />
                </Select.Option>
                <Select.Option value="IconLanguage">
                    <IconLanguage />
                </Select.Option>
                <Select.Option value="IconLayout">
                    <IconLayout />
                </Select.Option>
                <Select.Option value="IconLocation">
                    <IconLocation />
                </Select.Option>
                <Select.Option value="IconLock">
                    <IconLock />
                </Select.Option>
                <Select.Option value="IconLoop">
                    <IconLoop />
                </Select.Option>
                <Select.Option value="IconMan">
                    <IconMan />
                </Select.Option>
                <Select.Option value="IconMenu">
                    <IconMenu />
                </Select.Option>
                <Select.Option value="IconMindMapping">
                    <IconMindMapping />
                </Select.Option>
                <Select.Option value="IconMobile">
                    <IconMobile />
                </Select.Option>
                <Select.Option value="IconMoon">
                    <IconMoon />
                </Select.Option>
                <Select.Option value="IconMosaic">
                    <IconMosaic />
                </Select.Option>
                <Select.Option value="IconNav">
                    <IconNav />
                </Select.Option>
                <Select.Option value="IconNotificationClose">
                    <IconNotificationClose />
                </Select.Option>
                <Select.Option value="IconNotification">
                    <IconNotification />
                </Select.Option>
                <Select.Option value="IconPalette">
                    <IconPalette />
                </Select.Option>
                <Select.Option value="IconPen">
                    <IconPen />
                </Select.Option>
                <Select.Option value="IconPhone">
                    <IconPhone />
                </Select.Option>
                <Select.Option value="IconPrinter">
                    <IconPrinter />
                </Select.Option>
                <Select.Option value="IconPublic">
                    <IconPublic />
                </Select.Option>
                <Select.Option value="IconPushpin">
                    <IconPushpin />
                </Select.Option>
                <Select.Option value="IconQrcode">
                    <IconQrcode />
                </Select.Option>
                <Select.Option value="IconRobotAdd">
                    <IconRobotAdd />
                </Select.Option>
                <Select.Option value="IconRobot">
                    <IconRobot />
                </Select.Option>
                <Select.Option value="IconSafe">
                    <IconSafe />
                </Select.Option>
                <Select.Option value="IconSchedule">
                    <IconSchedule />
                </Select.Option>
                <Select.Option value="IconShake">
                    <IconShake />
                </Select.Option>
                <Select.Option value="IconSkin">
                    <IconSkin />
                </Select.Option>
                <Select.Option value="IconStamp">
                    <IconStamp />
                </Select.Option>
                <Select.Option value="IconStorage">
                    <IconStorage />
                </Select.Option>
                <Select.Option value="IconSubscribeAdd">
                    <IconSubscribeAdd />
                </Select.Option>
                <Select.Option value="IconSubscribe">
                    <IconSubscribe />
                </Select.Option>
                <Select.Option value="IconSubscribed">
                    <IconSubscribed />
                </Select.Option>
                <Select.Option value="IconSun">
                    <IconSun />
                </Select.Option>
                <Select.Option value="IconTag">
                    <IconTag />
                </Select.Option>
                <Select.Option value="IconTags">
                    <IconTags />
                </Select.Option>
                <Select.Option value="IconThunderbolt">
                    <IconThunderbolt />
                </Select.Option>
                <Select.Option value="IconTool">
                    <IconTool />
                </Select.Option>
                <Select.Option value="IconTrophy">
                    <IconTrophy />
                </Select.Option>
                <Select.Option value="IconUnlock">
                    <IconUnlock />
                </Select.Option>
                <Select.Option value="IconUserAdd">
                    <IconUserAdd />
                </Select.Option>
                <Select.Option value="IconUserGroup">
                    <IconUserGroup />
                </Select.Option>
                <Select.Option value="IconUser">
                    <IconUser />
                </Select.Option>
                <Select.Option value="IconVideoCamera">
                    <IconVideoCamera />
                </Select.Option>
                <Select.Option value="IconWifi">
                    <IconWifi />
                </Select.Option>
                <Select.Option value="IconWoman">
                    <IconWoman />
                </Select.Option>
            </Select>
        </React.Fragment>
    );
};

IconIndex.propTypes = {
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default IconIndex;
