import React from "react";
import {useOutletContext} from "react-router-dom";
import {Alert} from "@arco-design/web-react";
import PropTypes from "prop-types";

const ErrorIndex = ({style = {}, ...props}) => {
    return (
        <React.Fragment>
            {props.errorTitle ? (
                <div className="card" style={style}>
                    <Alert
                        type="error"
                        title={props.errorList.length == 0 ? null : props.errorTitle}
                        closable
                        content={
                            props.errorList.length == 0 ? (
                                <React.Fragment>{props.errorTitle}</React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {props.errorList.map((error, errorIndex) => {
                                        return (
                                            <React.Fragment key={errorIndex}>
                                                <div>
                                                    {errorIndex + 1}. {error}
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                                </React.Fragment>
                            )
                        }
                        onClose={() => {
                            props.setErrorTitle("");
                            props.setErrorList([]);
                        }}
                    />
                </div>
            ) : null}
        </React.Fragment>
    );
};

ErrorIndex.propTypes = {
    style: PropTypes.object,
    errorTitle: PropTypes.string.isRequired,
    errorList: PropTypes.array.isRequired,
    setErrorTitle: PropTypes.func.isRequired,
    setErrorList: PropTypes.func.isRequired,
};

export default ErrorIndex;
