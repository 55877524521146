export const defaultH2Style: Partial<CSSStyleDeclaration> = {
  fontSize: '13px',
  marginBottom: '0px',
  fontWeight: 'normal'
};

export const defaultContainerStyle: Partial<CSSStyleDeclaration> = {
  boxSizing: 'border-box'
};

export const defaultContentColumnStyle: Partial<CSSStyleDeclaration> = {
  display: 'inline-block',
  verticalAlign: 'top'
};

export const defaultKeyStyle: Partial<CSSStyleDeclaration> = {
  paddingTop: '0px',
  paddingBottom: '0px',
  // overflow: 'hidden',
  // textOverflow: 'ellipsis',
  textAlign: 'left',
  fontWeight: 'normal'
};

export const defaultAdaptiveKeyStyle: Partial<CSSStyleDeclaration> = {
  paddingTop: '0px',
  paddingBottom: '0px',
  textAlign: 'left',
  fontWeight: 'normal'
};

export const defaultValueStyle: Partial<CSSStyleDeclaration> = {
  paddingTop: '0px',
  paddingBottom: '0px',
  // overflow: 'hidden',
  textAlign: 'right',
  fontWeight: 'normal'
};

export const defaultShapeStyle: Partial<CSSStyleDeclaration> = {
  lineHeight: 'normal',
  boxSizing: 'border-box'
};
