import React, {useState, useEffect} from "react";
import {Input, Button, Dropdown, Menu, Space} from "@arco-design/web-react";
import {IconLanguage, IconCheckCircle, IconClose} from "@arco-design/web-react/icon";
import {useOutletContext} from "react-router-dom";
import PropTypes from "prop-types";

import LanguageSelect from "./select";

const LanguageInput = ({...props}) => {
    const outletContext = useOutletContext();
    const [isSelect, setIsSelect] = useState(false);
    const [languageName, setLanguageName] = useState({});

    useEffect(() => {}, []);

    useEffect(() => {
        setLanguageName(props.language[localStorage.getItem("locale")]);
    }, [localStorage.getItem("locale")]);

    useEffect(() => {
        setLanguageName(props.language[localStorage.getItem("locale")]);
    }, [props.language]);

    return (
        <React.Fragment>
            {props.language.languageId ? (
                <Input
                    placeholder={props.placeholder}
                    allowClear
                    disabled
                    addAfter={
                        <Dropdown
                            droplist={
                                <Menu
                                    onClickMenuItem={(key) => {
                                        if (key == "selectLanguage") {
                                            setIsSelect(true);
                                        } else if (key == "clearLanguage") {
                                            props.setLanguage({});
                                        }
                                    }}
                                >
                                    <Menu.Item key="selectLanguage">
                                        <Space>
                                            <IconCheckCircle />
                                            {outletContext.intl.formatMessage({id: "language.selectLanguage"})}
                                        </Space>
                                    </Menu.Item>
                                    <Menu.Item key="clearLanguage">
                                        <Space>
                                            <IconClose />
                                            {outletContext.intl.formatMessage({id: "language.clearLanguage"})}
                                        </Space>
                                    </Menu.Item>
                                </Menu>
                            }
                            trigger="click"
                        >
                            <Button type="primary" icon={<IconLanguage className="input_icon" />} />
                        </Dropdown>
                    }
                    value={languageName}
                />
            ) : (
                <Input
                    placeholder={props.placeholder}
                    allowClear
                    addAfter={
                        <Button
                            type="text"
                            icon={<IconLanguage className="input_icon" />}
                            onClick={() => {
                                setIsSelect(true);
                            }}
                        />
                    }
                    value={props.value}
                    onChange={(value) => {
                        props.onChange(value);
                    }}
                    onPressEnter={() => {
                        props.onPressEnter();
                    }}
                />
            )}

            {isSelect ? (
                <LanguageSelect
                    visible={isSelect}
                    onClose={() => {
                        setIsSelect(false);
                    }}
                    onSelect={(selectedList) => {
                        if (selectedList.length > 0) {
                            let language = selectedList[0];

                            props.setLanguage(language);
                            setLanguageName(language[localStorage.getItem("locale")]);
                        }

                        setIsSelect(false);
                    }}
                />
            ) : null}
        </React.Fragment>
    );
};

LanguageInput.propTypes = {
    languageId: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    onPressEnter: PropTypes.func.isRequired,
    language: PropTypes.object.isRequired,
    setLanguage: PropTypes.func.isRequired,
};

export default LanguageInput;
