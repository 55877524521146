import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {Modal, Space, Button} from "@arco-design/web-react";
import {IconCloseCircle, IconCheckCircle} from "@arco-design/web-react/icon";
import {useOutletContext} from "react-router-dom";
import PropTypes from "prop-types";

import DepartmentIndex from "./index";

const DepartmentSelect = ({...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const [breadcrumb, setBreadcrumb] = useState({});
    const [selectedList, setSelectedList] = useState([]);

    useEffect(() => {
        if (props.visible) {
            let breadcrumb = {
                name: outletContext.intl.formatMessage({id: "department.selectDepartment"}),
            };
            setBreadcrumb(breadcrumb);
        }
    }, [props.visible]);

    return (
        <React.Fragment>
            <Modal
                style={{top: "100px", width: main.width - 300 <= 1024 ? main.width + "px" : main.width - 300 + "px"}}
                title={<div className="arco-modal-title-text">{breadcrumb.name}</div>}
                visible={props.visible}
                focusLock={false}
                alignCenter={false}
                maskClosable={false}
                onCancel={() => {
                    props.onClose();
                }}
                footer={
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{flex: 1, marginRight: "8px"}}></div>
                        <Space>
                            <Button
                                icon={<IconCloseCircle />}
                                onClick={() => {
                                    props.onClose();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.close"})}
                            </Button>
                            <Space>
                                <Button
                                    icon={<IconCheckCircle />}
                                    type="primary"
                                    onClick={() => {
                                        props.onSelect(selectedList);
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.select"})}
                                </Button>
                            </Space>
                        </Space>
                    </div>
                }
            >
                <DepartmentIndex
                    autoHeight={true}
                    breadcrumb={breadcrumb}
                    onSelect={(selectedList) => {
                        setSelectedList(selectedList);
                    }}
                />
            </Modal>
        </React.Fragment>
    );
};

DepartmentSelect.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default DepartmentSelect;
