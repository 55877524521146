import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Menu} from "@arco-design/web-react";
import {Scrollbars} from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

import Util from "../../js/util";

import Icon from "../../component/icon";

const NavigationIndex = ({outletContext = {}, autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const main = useSelector((store) => store.main);

    useEffect(() => {}, []);

    return (
        <React.Fragment>
            <Scrollbars
                renderView={({style, ...props}) => {
                    return <div className="menu" style={style} {...props} />;
                }}
            >
                <Menu
                    openKeys={props.openKeys}
                    selectedKeys={props.selectedKeys}
                    onClickSubMenu={(key, openKeys) => {
                        props.setOpenKeys(openKeys);
                    }}
                    onClickMenuItem={(key, event, keyPath) => {
                        if (props.selectedKeys.length > 0 && props.selectedKeys[0] == key) {
                            return;
                        }

                        for (let i = 0; i < main.menuList.length; i++) {
                            let menu = main.menuList[i];
                            if (menu.childrenList.length > 0) {
                                for (let j = 0; j < menu.childrenList.length; j++) {
                                    let children = menu.childrenList[j];
                                    if (key == children.menuId) {
                                        if (props.isDrawer) {
                                            props.setIsDrawer(false);

                                            setTimeout(() => {
                                                Util.navigateTo(props, {
                                                    url: children.menuUrl,
                                                });
                                            }, 100);
                                        } else {
                                            Util.navigateTo(props, {
                                                url: children.menuUrl,
                                            });
                                        }

                                        break;
                                    }
                                }
                            } else {
                                if (key == menu.menuId) {
                                    if (props.isDrawer) {
                                        props.setIsDrawer(false);

                                        setTimeout(() => {
                                            Util.navigateTo(props, {
                                                url: menu.menuUrl,
                                            });
                                        }, 100);
                                    } else {
                                        Util.navigateTo(props, {
                                            url: menu.menuUrl,
                                        });
                                    }
                                }
                            }
                        }
                    }}
                >
                    {main.menuList.map((menu, menuIndex) => {
                        return (
                            <React.Fragment key={menuIndex}>
                                {menu.childrenList.length == 0 ? (
                                    <Menu.Item key={menu.menuId}>
                                        <div className="menu_row">
                                            {menu.menuIcon ? <Icon name={menu.menuIcon} className="menu_icon" /> : null}
                                            {menu.languageId ? outletContext.intl.formatMessage({id: menu.languageId}) : menu.menuName}
                                        </div>
                                    </Menu.Item>
                                ) : (
                                    <Menu.SubMenu
                                        key={menu.menuId}
                                        title={
                                            <div className="menu_row">
                                                {menu.menuIcon ? <Icon name={menu.menuIcon} className="menu_icon" /> : null}
                                                {menu.languageId ? outletContext.intl.formatMessage({id: menu.languageId}) : menu.menuName}
                                            </div>
                                        }
                                    >
                                        {menu.childrenList.map((children, childrenIndex) => {
                                            return (
                                                <React.Fragment key={childrenIndex}>
                                                    <Menu.Item key={children.menuId}>
                                                        <div className="menu_row">
                                                            {children.menuIcon ? <Icon name={children.menuIcon} className="menu_icon" /> : null}
                                                            {children.languageId
                                                                ? outletContext.intl.formatMessage({id: children.languageId})
                                                                : children.menuName}
                                                        </div>
                                                    </Menu.Item>
                                                </React.Fragment>
                                            );
                                        })}
                                    </Menu.SubMenu>
                                )}
                            </React.Fragment>
                        );
                    })}
                </Menu>
            </Scrollbars>
        </React.Fragment>
    );
};

NavigationIndex.propTypes = {
    outletContext: PropTypes.object.isRequired,
    openKeys: PropTypes.array.isRequired,
    setOpenKeys: PropTypes.func.isRequired,
    selectedKeys: PropTypes.array.isRequired,
    isDrawer: PropTypes.bool.isRequired,
    setIsDrawer: PropTypes.func.isRequired,
    imageUrl: PropTypes.string.isRequired,
};

export default Util.withRouter(NavigationIndex);
