import React, {useState, useRef, useEffect, forwardRef, useImperativeHandle} from "react";
import {useSelector} from "react-redux";
import {
    Message,
    Modal,
    Breadcrumb,
    Card,
    Grid,
    Form,
    Space,
    Button,
    Divider,
    Table,
    Pagination,
    Input,
    Dropdown,
    Menu,
    Tag,
    Select,
    Tree,
} from "@arco-design/web-react";
import {
    IconSearch,
    IconUndo,
    IconPlusCircle,
    IconEye,
    IconLock,
    IconDelete,
    IconUpload,
    IconDownload,
    IconMoreVertical,
    IconRefresh,
} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import {useOutletContext} from "react-router-dom";
import PropTypes from "prop-types";

import Util from "../../../../../nowui-common/js/util";

import Error from "../../../../../nowui-common/component/error";
import Import from "../../../../../nowui-common/component/import";
import Process from "../../../../../nowui-common/component/process";
import Loading from "../../../../../nowui-common/component/loading";
import Selected from "../../../../../nowui-common/component/selected";
import Sort from "../../../../../nowui-common/component/sort";

import UserDetail from "./detail";
import UserPassword from "./password";

const UserIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [form] = Form.useForm();
    const columnListRef = useRef([]);
    const [columnList, setColumnList] = useState([]);
    const [operationColumn, setOperationColumn] = useState({});
    const [isLoad, setIsLoad] = useState(false);
    const [isDetail, setIsDetail] = useState(false);
    const [isProcess, setIsProcess] = useState(false);
    const [processType, setProcessType] = useState(0);
    const [isImport, setIsImport] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [selectedIdList, setSelectedIdList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [isPassword, setIsPassword] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [breadcrumbList, setBreadcrumbList] = useState([]);
    const [action, setAction] = useState("");
    const [userId, setUserId] = useState("");
    const [userCount, setUserCount] = useState(0);
    const [userList, setUserList] = useState([]);
    const [userPageIndex, setUserPageIndex] = useState(1);
    const [userPageSize, setUserPageSize] = useState(10);
    const [sortList, setSortList] = useState([]);
    const [departmentId, setDepartmentId] = useState("");
    const [departmentName, setDepartmentName] = useState("");
    const [departmentList, setDepartmentList] = useState([]);
    const [departmentListTree, setDepartmentListTree] = useState([]);
    const [departmentExpandIdList, setDepartmentExpandIdList] = useState([]);

    useEffect(() => {
        handleLoadDepartmentList();

        handleSearchUser();

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        setErrorTitle("");
        setErrorList([]);

        let breadcrumbList = Util.getBreadcrumb(outletContext, main.menuList, "/user/index");
        setBreadcrumbList(breadcrumbList);

        let columnList = [
            {
                dataIndex: "userName",
                title: outletContext.intl.formatMessage({id: "user.userName"}),
            },
            {
                dataIndex: "userAccount",
                title: outletContext.intl.formatMessage({id: "user.userAccount"}),
            },
            {
                dataIndex: "userTypeLabel",
                title: outletContext.intl.formatMessage({id: "user.userType"}),
            },
            {
                dataIndex: "userCode",
                title: outletContext.intl.formatMessage({id: "user.userCode"}),
            },
            {
                dataIndex: "userStatus",
                title: outletContext.intl.formatMessage({id: "user.userStatus"}),
                render: (col, record, index) => (
                    <React.Fragment>
                        {record.userStatus == 0 ? (
                            <Tag color="#00b42a" size="medium">
                                {record.userStatusLabel}
                            </Tag>
                        ) : null}
                        {record.userStatus == 1 ? (
                            <Tag color="#f53f3f" size="medium">
                                {record.userStatusLabel}
                            </Tag>
                        ) : null}
                    </React.Fragment>
                ),
            },
            {
                dataIndex: "createTime",
                title: outletContext.intl.formatMessage({id: "global.createTime"}),
            },
        ];
        setColumnList(columnList);

        setOperationColumn({
            width: 200,
            fixed: "right",
            dataIndex: "operation",
            title: outletContext.intl.formatMessage({id: "global.operation"}),
            render: (col, record, index) => (
                <React.Fragment>
                    <Space>
                        {main.permissionList.indexOf(Util.md5("/user/backend/website/v1/item")) > -1 ? (
                            <Button
                                type="default"
                                icon={<IconEye />}
                                onClick={() => {
                                    setIsDetail(true);
                                    setAction("update");
                                    setUserId(record.userId);
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.view"})}
                            </Button>
                        ) : null}
                        {main.permissionList.indexOf(Util.md5("/user/backend/website/v1/update/password")) > -1 ? (
                            <Dropdown
                                droplist={
                                    <Menu
                                        onClickMenuItem={(key) => {
                                            if (key == "changePassword") {
                                                setIsPassword(true);
                                                setUserId(record.userId);
                                            }
                                        }}
                                    >
                                        <Menu.Item key="changePassword">
                                            <Space>
                                                <IconLock />
                                                {outletContext.intl.formatMessage({id: "user.changePassword"})}
                                            </Space>
                                        </Menu.Item>
                                    </Menu>
                                }
                                trigger="click"
                            >
                                <Button type="default">
                                    {outletContext.intl.formatMessage({id: "global.more"})}
                                    <IconMoreVertical />
                                </Button>
                            </Dropdown>
                        ) : null}
                    </Space>
                </React.Fragment>
            ),
        });

        Util.changeSortList(sortList, setSortList, columnList);
    }, [localStorage.getItem("locale")]);

    useEffect(() => {
        onSelect(selectedList);
    }, [selectedList]);

    useEffect(() => {
        columnListRef.current = columnList;
    }, [columnList]);

    useEffect(() => {
        let columnList = columnListRef.current;
        if (columnList.length == 0) {
            return;
        }
        for (let i = 0; i < columnList.length; i++) {
            let direction = "";
            for (let j = 0; j < sortList.length; j++) {
                if (columnList[i].dataIndex == sortList[j].field) {
                    direction = sortList[j].direction;
                    break;
                }
            }
            columnList[i].sortOrder = direction;
        }
        setColumnList([...columnList]);

        handleSearchUser();
    }, [sortList]);

    useEffect(() => {
        handleSearchUser();
    }, [departmentId]);

    useEffect(() => {
        if (departmentName) {
            handleSearchDepartmentList();
        } else {
            setDepartmentList(departmentListTree);
        }
    }, [departmentName]);

    const handleSearchDepartmentList = () => {
        const loop = (data) => {
            const result = [];
            data.forEach((item) => {
                if (item.departmentName.toLowerCase().indexOf(departmentName.toLowerCase()) > -1) {
                    result.push({...item});
                } else if (item.childrenList) {
                    const filterData = loop(item.childrenList);

                    if (filterData.length) {
                        result.push({...item, childrenList: filterData});
                    }
                }
            });
            return result;
        };

        let departmentList = loop(departmentListTree);
        setDepartmentList(departmentList);
    };

    const handleLoadDepartmentList = () => {
        if (main.permissionList.indexOf(Util.md5("/department/backend/website/v1/list/tree")) == -1) {
            return;
        }

        Util.request(outletContext, {
            url: process.env.API_URL + "/department/backend/website/v1/list/tree",
            controller: controller,
            data: {},
            success: (response) => {
                setDepartmentList(response.data);
                setDepartmentListTree(response.data);
                setDepartmentExpandIdList(Util.getExpandIdList(departmentExpandIdList, response.data, "departmentId"));
            },
            error: (response) => {
                Message.error(response.message);
            },
            complete: () => {},
        });
    };

    const handleSearchUser = () => {
        handleLoadUserList(1, userPageSize);
    };

    const handleLoadUserList = (userPageIndex, userPageSize) => {
        if (main.permissionList.indexOf(Util.md5("/user/backend/website/v1/list")) == -1) {
            return;
        }

        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        let data = form.getFieldsValue();
        data.departmentId = departmentId;
        data.sortList = sortList;
        data.pageIndex = userPageIndex;
        data.pageSize = userPageSize;

        Util.request(outletContext, {
            url: process.env.API_URL + "/user/backend/website/v1/list",
            controller: controller,
            data: data,
            success: (response) => {
                setUserCount(response.count);
                setUserList(response.data);
                setUserPageIndex(userPageIndex);
                setUserPageSize(userPageSize);

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleDeleteUser = () => {
        if (selectedIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: outletContext.intl.formatMessage({id: "global.deleteConfirmTitle"}),
            content: outletContext.intl.formatMessage({id: "global.deleteConfirmContent"}),
            okButtonProps: {
                status: "danger",
            },
            onOk: () => {
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                Util.request(outletContext, {
                    url: process.env.API_URL + "/user/backend/website/v1/delete",
                    controller: controller,
                    data: {
                        userIdList: selectedIdList,
                    },
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        setSelectedIdList([]);
                        setSelectedList([]);

                        handleLoadUserList(userPageIndex, userPageSize);
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            },
        });
    };

    const handleExportUserSearch = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        Message.loading({
            id: "loading",
            content: outletContext.intl.formatMessage({id: "global.loading"}),
            duration: 0,
        });

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        let data = form.getFieldsValue();
        data.sortList = sortList;

        Util.request(outletContext, {
            url: process.env.API_URL + "/user/backend/website/v1/export/search",
            controller: controller,
            data: data,
            success: (response) => {
                Message.success({
                    id: "loading",
                    content: response.message,
                });

                request.current = false;
                setIsLoad(false);

                setIsProcess(true);
                setProcessType(1);
            },
            error: (response) => {
                Message.error({
                    id: "loading",
                    content: response.message,
                });

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleExportUserSelected = () => {
        if (selectedIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }
        request.current = true;

        Message.loading({
            id: "loading",
            content: outletContext.intl.formatMessage({id: "global.loading"}),
            duration: 0,
        });

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.request(outletContext, {
            url: process.env.API_URL + "/user/backend/website/v1/export/selected",
            controller: controller,
            data: {
                userIdList: selectedIdList,
            },
            success: (response) => {
                Message.success({
                    id: "loading",
                    content: response.message,
                });

                request.current = false;
                setIsLoad(false);

                setIsProcess(true);
                setProcessType(1);
            },
            error: (response) => {
                Message.error({
                    id: "loading",
                    content: response.message,
                });

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    return (
        <React.Fragment>
            <Scrollbars
                autoHeight={autoHeight}
                autoHeightMax={main.height - 300}
                renderView={({style, ...props}) => {
                    return <div className="scrollbar" style={style} {...props} />;
                }}
            >
                <Breadcrumb className="breadcrumb">
                    {breadcrumbList.map((breadcrumb, breadcrumbIndex) => {
                        return <Breadcrumb.Item key={breadcrumbIndex}>{breadcrumb.name}</Breadcrumb.Item>;
                    })}
                    {props.breadcrumb ? <Breadcrumb.Item key={props.breadcrumb.name}>{props.breadcrumb.name}</Breadcrumb.Item> : null}
                </Breadcrumb>
                <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                <Form form={form} autoComplete="off" layout="vertical">
                    <div style={{display: "flex", flexDirection: main.width > 600 ? "row" : "column"}}>
                        <div style={main.width > 600 ? {width: "240px", flexShrink: 0} : {width: "100%"}}>
                            <Card
                                className="card"
                                style={{marginRight: "0px"}}
                                title={
                                    <Space align="center">
                                        {outletContext.intl.formatMessage({id: "global.organization"})}
                                        <Button
                                            size="mini"
                                            icon={<IconRefresh />}
                                            loading={isLoad}
                                            onClick={() => {
                                                setDepartmentName("");

                                                handleLoadDepartmentList();
                                            }}
                                        >
                                            {outletContext.intl.formatMessage({id: "global.refresh"})}
                                        </Button>
                                    </Space>
                                }
                                bordered={false}
                            >
                                <Input.Search
                                    placeholder="部门名称"
                                    onChange={(value) => {
                                        setDepartmentName(value);
                                    }}
                                />
                                <div className="card_tree">
                                    {departmentList.length > 0 ? (
                                        <Tree
                                            showLine
                                            blockNode
                                            size="large"
                                            fieldNames={{
                                                key: "departmentId",
                                                title: "departmentName",
                                                children: "childrenList",
                                            }}
                                            treeData={departmentList}
                                            expandedKeys={departmentExpandIdList}
                                            onExpand={(keys) => {
                                                setDepartmentExpandIdList(keys);
                                            }}
                                            renderTitle={({title}) => {
                                                if (departmentName) {
                                                    const index = title.toLowerCase().indexOf(departmentName.toLowerCase());

                                                    if (index === -1) {
                                                        return title;
                                                    }

                                                    const prefix = title.substr(0, index);
                                                    const suffix = title.substr(index + departmentName.length);
                                                    return (
                                                        <span>
                                                            {prefix}
                                                            <span style={{color: "var(--color-primary-light-4)"}}>
                                                                {title.substr(index, departmentName.length)}
                                                            </span>
                                                            {suffix}
                                                        </span>
                                                    );
                                                }

                                                return title;
                                            }}
                                            selectedKeys={[departmentId]}
                                            onSelect={(selectedKeys) => {
                                                if (selectedKeys.length > 0) {
                                                    if (departmentId == selectedKeys[0]) {
                                                        setDepartmentId("");
                                                    } else {
                                                        setDepartmentId(selectedKeys[0]);
                                                    }
                                                } else {
                                                    setDepartmentId("");
                                                }
                                            }}
                                        />
                                    ) : null}
                                </div>
                            </Card>
                        </div>
                        <div style={main.width > 600 ? {flex: 1, width: 0} : {width: "100%"}}>
                            <Card className="card" title={outletContext.intl.formatMessage({id: "global.searchTable"})} bordered={false}>
                                <Grid.Row gutter={20}>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                        <Form.Item label={outletContext.intl.formatMessage({id: "user.userName"})} field="userName">
                                            <Input
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                allowClear
                                                onPressEnter={() => {
                                                    handleSearchUser();
                                                }}
                                            />
                                        </Form.Item>
                                    </Grid.Col>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                        <Form.Item label={outletContext.intl.formatMessage({id: "user.userAccount"})} field="userAccount">
                                            <Input
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                allowClear
                                                onPressEnter={() => {
                                                    handleSearchUser();
                                                }}
                                            />
                                        </Form.Item>
                                    </Grid.Col>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                        <Form.Item label={outletContext.intl.formatMessage({id: "user.userType"})} field="userType">
                                            <Select
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseSelect"})}
                                                allowClear
                                                onChange={(event) => {
                                                    handleSearchUser();
                                                }}
                                            >
                                                <Select.Option value={0}>管理员</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Grid.Col>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                        <Form.Item label={outletContext.intl.formatMessage({id: "user.userCode"})} field="userCode">
                                            <Input
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                allowClear
                                                onPressEnter={() => {
                                                    handleSearchUser();
                                                }}
                                            />
                                        </Form.Item>
                                    </Grid.Col>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                        <Form.Item label={outletContext.intl.formatMessage({id: "user.userStatus"})} field="userStatus">
                                            <Select
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseSelect"})}
                                                allowClear
                                                onChange={(event) => {
                                                    handleSearchUser();
                                                }}
                                            >
                                                <Select.Option value={0}>在职</Select.Option>
                                                <Select.Option value={1}>离职</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Grid.Col>
                                </Grid.Row>
                                <Space className="action" wrap>
                                    {main.permissionList.indexOf(Util.md5("/user/backend/website/v1/list")) > -1 ? (
                                        <React.Fragment>
                                            <Button
                                                type="primary"
                                                icon={<IconSearch />}
                                                loading={isLoad}
                                                onClick={() => {
                                                    handleSearchUser();
                                                }}
                                            >
                                                {outletContext.intl.formatMessage({id: "global.search"})}
                                            </Button>
                                            <Button
                                                icon={<IconUndo />}
                                                loading={isLoad}
                                                onClick={() => {
                                                    form.resetFields();

                                                    setSelectedIdList([]);
                                                    setSelectedList([]);
                                                    setSortList([]);
                                                }}
                                            >
                                                {outletContext.intl.formatMessage({id: "global.reset"})}
                                            </Button>
                                        </React.Fragment>
                                    ) : null}
                                    {main.permissionList.indexOf(Util.md5("/user/backend/website/v1/save")) > -1 ||
                                    main.permissionList.indexOf(Util.md5("/user/backend/website/v1/delete")) > -1 ? (
                                        <React.Fragment>
                                            <Divider type="vertical" />
                                            {main.permissionList.indexOf(Util.md5("/user/backend/website/v1/save")) > -1 ? (
                                                <Button
                                                    icon={<IconPlusCircle />}
                                                    onClick={() => {
                                                        setIsDetail(true);
                                                        setAction("save");
                                                        setUserId("");
                                                    }}
                                                >
                                                    {outletContext.intl.formatMessage({id: "global.save"})}
                                                </Button>
                                            ) : null}
                                            {main.permissionList.indexOf(Util.md5("/user/backend/website/v1/delete")) > -1 ? (
                                                <Button
                                                    icon={<IconDelete />}
                                                    disabled={selectedIdList.length == 0}
                                                    onClick={() => {
                                                        handleDeleteUser();
                                                    }}
                                                >
                                                    {outletContext.intl.formatMessage({id: "global.delete"})}
                                                </Button>
                                            ) : null}
                                        </React.Fragment>
                                    ) : null}
                                    {main.permissionList.indexOf(Util.md5("/user/backend/website/v1/upload/process")) > -1 ||
                                    main.permissionList.indexOf(Util.md5("/user/backend/website/v1/export/search")) > -1 ? (
                                        <React.Fragment>
                                            <Divider type="vertical" />
                                            {main.permissionList.indexOf(Util.md5("/user/backend/website/v1/upload/process")) > -1 ? (
                                                <Button
                                                    icon={<IconUpload />}
                                                    onClick={() => {
                                                        setIsImport(true);
                                                    }}
                                                >
                                                    {outletContext.intl.formatMessage({id: "global.import"})}
                                                </Button>
                                            ) : null}
                                            {main.permissionList.indexOf(Util.md5("/user/backend/website/v1/export/search")) > -1 ? (
                                                <Dropdown
                                                    droplist={
                                                        <Menu
                                                            onClickMenuItem={(key) => {
                                                                if (key == "search") {
                                                                    handleExportUserSearch();
                                                                } else if (key == "selected") {
                                                                    handleExportUserSelected();
                                                                } else if (key == "history") {
                                                                    setIsProcess(true);
                                                                    setProcessType(1);
                                                                }
                                                            }}
                                                        >
                                                            <Menu.Item key="search">
                                                                {outletContext.intl.formatMessage({id: "global.export"})}
                                                            </Menu.Item>
                                                            <Menu.Item key="selected" disabled={selectedIdList.length == 0}>
                                                                {outletContext.intl.formatMessage({id: "global.exportSelected"})}{" "}
                                                                {selectedIdList.length}
                                                            </Menu.Item>
                                                            <Divider style={{margin: "4px 0"}} />
                                                            <Menu.Item key="history">
                                                                {outletContext.intl.formatMessage({id: "global.exportHistory"})}
                                                            </Menu.Item>
                                                        </Menu>
                                                    }
                                                    trigger="click"
                                                >
                                                    <Button icon={<IconDownload />}>{outletContext.intl.formatMessage({id: "global.export"})}</Button>
                                                </Dropdown>
                                            ) : null}
                                        </React.Fragment>
                                    ) : null}
                                </Space>
                                <Sort sortList={sortList} setSortList={setSortList} />
                                <Table
                                    className="table"
                                    columns={columnList.concat([operationColumn])}
                                    rowKey="userId"
                                    data={userList}
                                    rowSelection={{
                                        type: selectType,
                                        columnWidth: 50,
                                        selectedRowKeys: selectedIdList,
                                        onChange: (selectedRowKeys) => {
                                            setSelectedIdList(Util.getSelectedIdList(selectedIdList, userList, "userId", selectedRowKeys));
                                            setSelectedList(Util.getSelectedList(selectedList, userList, "userId", selectedRowKeys));
                                        },
                                    }}
                                    scroll={{x: 50 + columnList.length * 150 + operationColumn.width}}
                                    border={false}
                                    pagination={false}
                                    onChange={(pagination, sorter, filters) => {
                                        setSortList(Util.getSortList(sortList, sorter, columnList));
                                    }}
                                />
                                <div className="pagination">
                                    <div className="pagination_selected">
                                        {main.permissionList.indexOf(Util.md5("/user/backend/website/v1/list")) > -1 ? (
                                            <React.Fragment>
                                                <Button
                                                    onClick={() => {
                                                        setIsSelected(true);
                                                    }}
                                                >
                                                    {outletContext.intl.formatMessage({id: "global.selected"})} {selectedIdList.length}
                                                </Button>
                                            </React.Fragment>
                                        ) : null}
                                    </div>
                                    {typeof userCount == "undefined" ? null : (
                                        <Pagination
                                            current={userPageIndex}
                                            total={userCount}
                                            simple={main.isMobile}
                                            showTotal
                                            sizeCanChange={!main.isMobile}
                                            onChange={(pageIndex, pageSize) => {
                                                handleLoadUserList(pageIndex, pageSize);
                                            }}
                                        />
                                    )}
                                </div>
                            </Card>
                        </div>
                    </div>
                </Form>
            </Scrollbars>
            {isLoad ? <Loading /> : null}
            {isDetail ? (
                <UserDetail
                    visible={isDetail}
                    action={action}
                    userId={userId}
                    onClose={() => {
                        setIsDetail(false);
                    }}
                    onSubmit={() => {
                        setIsDetail(false);

                        handleLoadUserList(userPageIndex, userPageSize);
                    }}
                />
            ) : null}
            {isProcess ? (
                <Process
                    visible={isProcess}
                    apiUrl={process.env.API_URL}
                    processModule="user"
                    processType={processType}
                    onClose={() => {
                        setIsProcess(false);
                    }}
                />
            ) : null}
            {isImport ? (
                <Import
                    visible={isImport}
                    name={outletContext.intl.formatMessage({id: "user"})}
                    templateUrl={process.env.API_URL + "/user/backend/website/v1/export/template"}
                    uploadUrl={process.env.API_URL + "/user/backend/website/v1/upload/process"}
                    startUrl={process.env.API_URL + "/user/backend/website/v1/import/process"}
                    accept=".xls,.xlsx"
                    onClose={() => {
                        setIsImport(false);
                    }}
                    onHistory={() => {
                        setIsProcess(true);
                        setProcessType(0);
                    }}
                />
            ) : null}
            {isSelected ? (
                <Selected
                    visible={isSelected}
                    url={process.env.API_URL + "/user/backend/website/v1/list/selected"}
                    selectedIdListKey="userIdList"
                    selectedIdList={selectedIdList}
                    sortList={sortList}
                    rowKey="userId"
                    columnList={columnList}
                    onClose={() => {
                        setIsSelected(false);
                    }}
                    onRemove={(idList) => {
                        setSelectedIdList(Util.removeSelectedIdList(selectedIdList, idList));
                        setSelectedList(Util.removeSelectedList(selectedList, "userId", idList));
                    }}
                />
            ) : null}
            {isPassword ? (
                <UserPassword
                    visible={isPassword}
                    userId={userId}
                    onClose={() => {
                        setIsPassword(false);
                    }}
                    onSubmit={() => {
                        setIsPassword(false);
                    }}
                />
            ) : null}
        </React.Fragment>
    );
};

UserIndex.propTypes = {
    autoHeight: PropTypes.bool,
    breadcrumb: PropTypes.object,
    selectType: PropTypes.string,
    onSelect: PropTypes.func,
};

export default Util.withRouter(UserIndex);
