import React, {useState, useRef, useEffect} from "react";
import {useOutlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Message, Tabs, Drawer} from "@arco-design/web-react";
import {IconMenu} from "@arco-design/web-react/icon";
import {KeepAlive, useAliveController} from "react-activation";
import {useResizeDetector} from "react-resize-detector";
import {injectIntl} from "react-intl";

import Util from "../../../../../nowui-common/js/util";
import Websocket from "../../../../../nowui-common/js/websocket";

import Help from "../../../../../nowui-common/component/help";
import Loading from "../../../../../nowui-common/component/loading";
import Navigation from "../../../../../nowui-common/component/navigation";

const Main = ({...props}) => {
    const outlet = useOutlet({intl: props.intl});
    const dispatch = useDispatch();
    const {drop, clear} = useAliveController();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const {width, height, ref} = useResizeDetector();
    const [isLaunch, setIsLaunch] = useState(false);
    const [isDrawer, setIsDrawer] = useState(false);
    const [tabId, setTabId] = useState("");
    const [tabList, setTabList] = useState([]);
    const [openKeys, setOpenKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);

    useEffect(() => {
        let authorization = localStorage.getItem("authorization");
        if (!authorization) {
            Util.navigateTo(props, {
                url: "/login",
            });

            return;
        }

        let locale = localStorage.getItem("locale");
        if (locale) {
            dispatch({
                type: "main",
                data: {
                    locale: locale,
                },
            });
        }

        let theme = localStorage.getItem("theme");
        if (theme) {
            dispatch({
                type: "main",
                data: {
                    theme: theme,
                },
            });
        }

        handleLoadProfile();

        Websocket.connect();

        return () => {
            controller.abort();

            clear();

            dispatch({
                type: "main",
                data: {
                    isLogin: false,
                },
            });
        };
    }, []);

    useEffect(() => {
        if (width) {
            dispatch({
                type: "main",
                data: {
                    width: width,
                    isMobile: width <= 1024,
                },
            });
        }
    }, [width]);

    useEffect(() => {
        if (height) {
            dispatch({
                type: "main",
                data: {
                    height: height,
                },
            });
        }
    }, [height]);

    useEffect(() => {
        if (isLaunch) {
            handleSelectedKeys(props.location.pathname, main.menuList);
        }
    }, [props.location.pathname]);

    const handleSelectedKeys = (url, menuList) => {
        let openKeys = [];
        let selectedKeys = [];
        let tabId = "";
        let tabName = "";
        let languageId = "";
        let tabUrl = "";
        for (let i = 0; i < menuList.length; i++) {
            let menu = menuList[i];
            if (menu.childrenList.length > 0) {
                for (let j = 0; j < menu.childrenList.length; j++) {
                    let children = menu.childrenList[j];
                    if (url == children.menuUrl) {
                        openKeys.push(menu.menuId);

                        selectedKeys.push(menu.menuId);
                        selectedKeys.push(children.menuId);

                        tabId = children.menuId;
                        tabName = children.menuName;
                        languageId = children.languageId;
                        tabUrl = children.menuUrl;

                        break;
                    }
                }
            } else {
                if (url == menu.menuUrl) {
                    openKeys.push(menu.menuId);

                    selectedKeys.push(menu.menuId);

                    tabId = menu.menuId;
                    tabName = menu.menuName;
                    languageId = menu.languageId;
                    tabUrl = menu.menuUrl;
                }
            }
            if (selectedKeys.length > 0) {
                break;
            }
        }
        if (isLaunch == false) {
            setOpenKeys(openKeys);
        }
        setSelectedKeys(selectedKeys);

        if (selectedKeys.length > 0) {
            setIsLaunch(true);

            let isExist = false;
            for (let i = 0; i < tabList.length; i++) {
                let tab = tabList[i];
                if (tabId == tab.tabId) {
                    isExist = true;
                }
            }
            if (!isExist) {
                tabList.push({
                    active: true,
                    tabId: tabId,
                    tabName: tabName,
                    languageId: languageId,
                    tabUrl: tabUrl,
                });
            }
            setTabId(tabId);
            setTabList(tabList);
        } else {
            setIsLaunch(false);

            Util.navigateTo(props, {
                url: "/notfound",
            });
        }
    };

    const handleLoadProfile = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        Util.request(
            {
                intl: props.intl,
            },
            {
                url: process.env.API_URL + "/user/backend/website/v1/profile",
                controller: controller,
                data: {},
                success: (response) => {
                    Util.emitter.emit("language", {
                        languageList: response.data.languageList,
                    });

                    let menuList = response.data.menuList;

                    dispatch({
                        type: "main",
                        data: {
                            isLogin: true,
                            menuList: menuList,
                            permissionList: response.data.permissionList,
                        },
                    });

                    if (props.location.pathname == "/") {
                        let url = "";
                        if (menuList.length > 0) {
                            let menu = menuList[0];
                            if (menu.childrenList.length > 0) {
                                let children = menu.childrenList[0];
                                url = children.menuUrl;
                            } else {
                                url = menu.menuUrl;
                            }
                        }
                        if (url) {
                            Util.navigateTo(props, {
                                url: url,
                            });

                            handleSelectedKeys(url, menuList);
                        }
                    } else {
                        handleSelectedKeys(props.location.pathname, menuList);
                    }

                    request.current = false;
                },
                error: (response) => {
                    request.current = false;

                    if (response.code == 100) {
                        Util.redirectTo(props, {
                            url: "/login",
                        });
                    }
                },
                complete: () => {},
            }
        );
    };

    return (
        <React.Fragment>
            {isLaunch ? (
                <div ref={ref} className="layout">
                    {main.isMobile ? null : (
                        <div className="sider">
                            <div className="title">
                                <div className="title_text">{document.title}</div>
                            </div>
                            <Navigation
                                outletContext={{
                                    intl: props.intl,
                                }}
                                openKeys={openKeys}
                                setOpenKeys={setOpenKeys}
                                selectedKeys={selectedKeys}
                                isDrawer={isDrawer}
                                setIsDrawer={setIsDrawer}
                                imageUrl={process.env.FILE_URL}
                            />
                        </div>
                    )}
                    <div className="content">
                        {main.isMobile ? (
                            <div className="title_mobile">
                                <div className="title_text">{document.title}</div>
                                <IconMenu
                                    className="title_menu"
                                    onClick={() => {
                                        setIsDrawer(true);
                                    }}
                                />
                            </div>
                        ) : null}
                        <div className="header">
                            <div className="tabs">
                                <Tabs
                                    activeTab={tabId}
                                    type="card-gutter"
                                    size="large"
                                    editable={tabList.length > 1}
                                    showAddButton={false}
                                    onChange={(key) => {
                                        for (let i = 0; i < tabList.length; i++) {
                                            let tab = tabList[i];
                                            if (tab.tabId == key) {
                                                Util.navigateTo(props, {
                                                    url: tab.tabUrl,
                                                });

                                                break;
                                            }
                                        }
                                    }}
                                    onDeleteTab={(key) => {
                                        let index = -1;
                                        for (let i = 0; i < tabList.length; i++) {
                                            let tab = tabList[i];
                                            if (tab.tabId == key) {
                                                index = i;

                                                break;
                                            }
                                        }

                                        if (index > -1) {
                                            drop(tabList[index].tabUrl);

                                            if (tabList.length == index + 1) {
                                                Util.navigateTo(props, {
                                                    url: tabList[index - 1].tabUrl,
                                                });
                                            } else {
                                                Util.navigateTo(props, {
                                                    url: tabList[index + 1].tabUrl,
                                                });
                                            }

                                            tabList.splice(index, 1);
                                            setTabList([...tabList]);
                                        }
                                    }}
                                >
                                    {tabList.map((tab) => {
                                        return (
                                            <Tabs.TabPane
                                                destroyOnHide
                                                key={tab.tabId}
                                                title={tab.languageId ? props.intl.formatMessage({id: tab.languageId}) : tab.tabName}
                                            ></Tabs.TabPane>
                                        );
                                    })}
                                </Tabs>
                            </div>
                            {main.isMobile ? null : (
                                <Help
                                    outletContext={{
                                        intl: props.intl,
                                    }}
                                    logoutUrl={process.env.API_URL + "/user/backend/website/v1/logout"}
                                    changePasswordUrl={process.env.API_URL + "/user/backend/website/v1/change/password"}
                                    isDrawer={isDrawer}
                                    setIsDrawer={setIsDrawer}
                                />
                            )}
                        </div>
                        {isLaunch ? (
                            <KeepAlive
                                key={props.location.pathname}
                                id={props.location.pathname}
                                name={props.location.pathname}
                                cacheKey={props.location.pathname}
                            >
                                {outlet}
                            </KeepAlive>
                        ) : null}
                    </div>
                </div>
            ) : (
                <Loading />
            )}
            <Drawer
                title={
                    <React.Fragment>
                        <Help
                            outletContext={{
                                intl: props.intl,
                            }}
                            logoutUrl={process.env.API_URL + "/user/backend/website/v1/logout"}
                            changePasswordUrl={process.env.API_URL + "/user/backend/website/v1/change/password"}
                            isDrawer={isDrawer}
                            setIsDrawer={setIsDrawer}
                        />
                    </React.Fragment>
                }
                footer={null}
                visible={isDrawer}
                focusLock={false}
                onOk={() => {
                    setIsDrawer(false);
                }}
                onCancel={() => {
                    setIsDrawer(false);
                }}
            >
                <Navigation
                    outletContext={{
                        intl: props.intl,
                    }}
                    openKeys={openKeys}
                    setOpenKeys={setOpenKeys}
                    selectedKeys={selectedKeys}
                    isDrawer={isDrawer}
                    setIsDrawer={setIsDrawer}
                    imageUrl={process.env.FILE_URL}
                />
            </Drawer>
        </React.Fragment>
    );
};

Main.propTypes = {};

export default Util.withRouter(injectIntl(Main));
