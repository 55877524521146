import React, {useState, useRef, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useSelector} from "react-redux";
import {
    Message,
    Modal,
    Breadcrumb,
    Card,
    Grid,
    Form,
    Space,
    Button,
    Divider,
    Table,
    Pagination,
    Input,
    Dropdown,
    Menu,
    Select,
    DatePicker,
} from "@arco-design/web-react";
import {
    IconSearch,
    IconUndo,
    IconPlusCircle,
    IconEye,
    IconDelete,
    IconUpload,
    IconDownload,
    IconMoreVertical,
    IconPlayCircle,
} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

import Util from "../../../../../nowui-common/js/util";

import Error from "../../../../../nowui-common/component/error";
import Import from "../../../../../nowui-common/component/import";
import Process from "../../../../../nowui-common/component/process";
import Loading from "../../../../../nowui-common/component/loading";
import Selected from "../../../../../nowui-common/component/selected";
import Sort from "../../../../../nowui-common/component/sort";

import LogMqDetail from "./detail";

const LogMqIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [form] = Form.useForm();
    const columnListRef = useRef([]);
    const [columnList, setColumnList] = useState([]);
    const [operationColumn, setOperationColumn] = useState({});
    const [isLoad, setIsLoad] = useState(false);
    const [isDetail, setIsDetail] = useState(false);
    const [isProcess, setIsProcess] = useState(false);
    const [processType, setProcessType] = useState(0);
    const [isImport, setIsImport] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [selectedIdList, setSelectedIdList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [breadcrumbList, setBreadcrumbList] = useState([]);
    const [action, setAction] = useState("");
    const [id, setId] = useState("");
    const [logMqCount, setLogMqCount] = useState(0);
    const [logMqList, setLogMqList] = useState([]);
    const [logMqPageIndex, setLogMqPageIndex] = useState(1);
    const [logMqPageSize, setLogMqPageSize] = useState(10);
    const [sortList, setSortList] = useState([]);

    useEffect(() => {
        handleSearchLogMq();

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        setErrorTitle("");
        setErrorList([]);

        let breadcrumbList = Util.getBreadcrumb(outletContext, main.menuList, "/log/mq/index");
        setBreadcrumbList(breadcrumbList);

        let columnList = [
            {
                dataIndex: "id",
                title: outletContext.intl.formatMessage({id: "logMq.id"}),
            },
            {
                dataIndex: "logId",
                title: outletContext.intl.formatMessage({id: "logMq.logId"}),
            },
            {
                dataIndex: "type",
                title: outletContext.intl.formatMessage({id: "logMq.type"}),
            },
            {
                dataIndex: "topic",
                title: outletContext.intl.formatMessage({id: "logMq.topic"}),
            },
            {
                dataIndex: "message",
                title: outletContext.intl.formatMessage({id: "logMq.message"}),
            },
            {
                dataIndex: "exception",
                title: outletContext.intl.formatMessage({id: "logMq.exception"}),
            },
            {
                dataIndex: "duration",
                title: outletContext.intl.formatMessage({id: "logMq.duration"}),
            },
            {
                dataIndex: "statusLabel",
                title: outletContext.intl.formatMessage({id: "logMq.status"}),
            },
            {
                dataIndex: "createTime",
                title: outletContext.intl.formatMessage({id: "global.createTime"}),
            },
        ];
        setColumnList(columnList);

        setOperationColumn({
            width: 200,
            fixed: "right",
            dataIndex: "operation",
            title: outletContext.intl.formatMessage({id: "global.operation"}),
            render: (col, record, index) => (
                <React.Fragment>
                    <Space>
                        {main.permissionList.indexOf(Util.md5("/log/mq/backend/website/v1/item")) > -1 ? (
                            <Button
                                type="default"
                                icon={<IconEye />}
                                onClick={() => {
                                    setIsDetail(true);
                                    setAction("update");
                                    setId(record.id);
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.view"})}
                            </Button>
                        ) : null}
                        {main.permissionList.indexOf(Util.md5("/log/mq/backend/website/v1/retry")) > -1 &&
                        (record.status == 0 || record.status == 2 || record.status == 4) ? (
                            <Dropdown
                                droplist={
                                    <Menu
                                        onClickMenuItem={(key) => {
                                            if (key == "retry") {
                                                handleRetryLogMq(record.id);
                                            }
                                        }}
                                    >
                                        <Menu.Item key="retry">
                                            <Space>
                                                <IconPlayCircle />
                                                {outletContext.intl.formatMessage({id: "global.retry"})}
                                            </Space>
                                        </Menu.Item>
                                    </Menu>
                                }
                                trigger="click"
                            >
                                <Button type="default">
                                    {outletContext.intl.formatMessage({id: "global.more"})}
                                    <IconMoreVertical />
                                </Button>
                            </Dropdown>
                        ) : null}
                    </Space>
                </React.Fragment>
            ),
        });

        Util.changeSortList(sortList, setSortList, columnList);
    }, [localStorage.getItem("locale")]);

    useEffect(() => {
        onSelect(selectedList);
    }, [selectedList]);

    useEffect(() => {
        columnListRef.current = columnList;
    }, [columnList]);

    useEffect(() => {
        let columnList = columnListRef.current;
        if (columnList.length == 0) {
            return;
        }
        for (let i = 0; i < columnList.length; i++) {
            let direction = "";
            for (let j = 0; j < sortList.length; j++) {
                if (columnList[i].dataIndex == sortList[j].field) {
                    direction = sortList[j].direction;
                    break;
                }
            }
            columnList[i].sortOrder = direction;
        }
        setColumnList([...columnList]);

        handleSearchLogMq();
    }, [sortList]);

    const handleSearchLogMq = () => {
        handleLoadLogMqList(1, logMqPageSize);
    };

    const handleLoadLogMqList = (logMqPageIndex, logMqPageSize) => {
        if (main.permissionList.indexOf(Util.md5("/log/mq/backend/website/v1/list")) == -1) {
            return;
        }

        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        let data = form.getFieldsValue();
        data.sortList = sortList;
        data.pageIndex = logMqPageIndex;
        data.pageSize = logMqPageSize;

        Util.request(outletContext, {
            url: process.env.API_URL + "/log/mq/backend/website/v1/list",
            controller: controller,
            data: data,
            success: (response) => {
                setLogMqCount(response.count);
                setLogMqList(response.data);
                setLogMqPageIndex(logMqPageIndex);
                setLogMqPageSize(logMqPageSize);

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleDeleteLogMq = () => {
        if (selectedIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: outletContext.intl.formatMessage({id: "global.deleteConfirmTitle"}),
            content: outletContext.intl.formatMessage({id: "global.deleteConfirmContent"}),
            okButtonProps: {
                status: "danger",
            },
            onOk: () => {
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                Util.request(outletContext, {
                    url: process.env.API_URL + "/log/mq/backend/website/v1/delete",
                    controller: controller,
                    data: {
                        idList: selectedIdList,
                    },
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        setSelectedIdList([]);
                        setSelectedList([]);

                        handleLoadLogMqList(logMqPageIndex, logMqPageSize);
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            },
        });
    };

    const handleExportLogMqSearch = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        Message.loading({
            id: "loading",
            content: outletContext.intl.formatMessage({id: "global.loading"}),
            duration: 0,
        });

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        let data = form.getFieldsValue();
        data.sortList = sortList;

        Util.request(outletContext, {
            url: process.env.API_URL + "/log/mq/backend/website/v1/export/search",
            controller: controller,
            data: data,
            success: (response) => {
                Message.success({
                    id: "loading",
                    content: response.message,
                });

                request.current = false;
                setIsLoad(false);

                setIsProcess(true);
                setProcessType(1);
            },
            error: (response) => {
                Message.error({
                    id: "loading",
                    content: response.message,
                });

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleExportLogMqSelected = () => {
        if (selectedIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }
        request.current = true;

        Message.loading({
            id: "loading",
            content: outletContext.intl.formatMessage({id: "global.loading"}),
            duration: 0,
        });

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.request(outletContext, {
            url: process.env.API_URL + "/log/mq/backend/website/v1/export/selected",
            controller: controller,
            data: {
                idList: selectedIdList,
            },
            success: (response) => {
                Message.success({
                    id: "loading",
                    content: response.message,
                });

                request.current = false;
                setIsLoad(false);

                setIsProcess(true);
                setProcessType(1);
            },
            error: (response) => {
                Message.error({
                    id: "loading",
                    content: response.message,
                });

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleRetryLogMq = (id) => {
        if (request.current) {
            return;
        }

        form.validate()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                data.id = id;

                Util.request(outletContext, {
                    url: process.env.API_URL + "/log/mq/backend/website/v1/retry",
                    controller: controller,
                    data: data,
                    success: (response) => {
                        setTimeout(() => {
                            Message.success({
                                id: "loading",
                                content: response.message,
                            });

                            request.current = false;
                            setIsLoad(false);

                            handleLoadLogMqList(logMqPageIndex, logMqPageSize);
                        }, 1000);
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV == "development") {
                    console.log(error);
                }
            });
    };

    return (
        <React.Fragment>
            <Scrollbars
                autoHeight={autoHeight}
                autoHeightMax={main.height - 300}
                renderView={({style, ...props}) => {
                    return <div className="scrollbar" style={style} {...props} />;
                }}
            >
                <Breadcrumb className="breadcrumb">
                    {breadcrumbList.map((breadcrumb, breadcrumbIndex) => {
                        return <Breadcrumb.Item key={breadcrumbIndex}>{breadcrumb.name}</Breadcrumb.Item>;
                    })}
                    {props.breadcrumb ? <Breadcrumb.Item key={props.breadcrumb.name}>{props.breadcrumb.name}</Breadcrumb.Item> : null}
                </Breadcrumb>
                <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                <Form form={form} autoComplete="off" layout="vertical">
                    <Card className="card" title={outletContext.intl.formatMessage({id: "global.searchTable"})} bordered={false}>
                        <Grid.Row gutter={20}>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "logMq.id"})} field="id">
                                    <Input
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                        allowClear
                                        onPressEnter={() => {
                                            handleSearchLogMq();
                                        }}
                                    />
                                </Form.Item>
                            </Grid.Col>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "logMq.logId"})} field="logId">
                                    <Input
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                        allowClear
                                        onPressEnter={() => {
                                            handleSearchLogMq();
                                        }}
                                    />
                                </Form.Item>
                            </Grid.Col>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "logMq.type"})} field="type">
                                    <Select
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                        allowClear
                                        onChange={(event) => {
                                            handleSearchLogMq();
                                        }}
                                    >
                                        <Select.Option value={0}>kafka</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Grid.Col>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "logMq.topic"})} field="topic">
                                    <Input
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                        allowClear
                                        onPressEnter={() => {
                                            handleSearchLogMq();
                                        }}
                                    />
                                </Form.Item>
                            </Grid.Col>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "logMq.message"})} field="message">
                                    <Input
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                        allowClear
                                        onPressEnter={() => {
                                            handleSearchLogMq();
                                        }}
                                    />
                                </Form.Item>
                            </Grid.Col>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "logMq.status"})} field="status">
                                    <Select
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                        allowClear
                                        onChange={(event) => {
                                            handleSearchLogMq();
                                        }}
                                    >
                                        <Select.Option value={0}>发送待确认</Select.Option>
                                        <Select.Option value={1}>发送成功</Select.Option>
                                        <Select.Option value={2}>发送失败</Select.Option>
                                        <Select.Option value={3}>消费成功</Select.Option>
                                        <Select.Option value={4}>消费失败</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Grid.Col>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "global.startTime"})} field="startTime">
                                    <DatePicker placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})} showTime allowClear />
                                </Form.Item>
                            </Grid.Col>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "global.endTime"})} field="endTime">
                                    <DatePicker placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})} showTime allowClear />
                                </Form.Item>
                            </Grid.Col>
                        </Grid.Row>
                        <Space className="action" wrap>
                            {main.permissionList.indexOf(Util.md5("/log/mq/backend/website/v1/list")) > -1 ? (
                                <React.Fragment>
                                    <Button
                                        type="primary"
                                        icon={<IconSearch />}
                                        loading={isLoad}
                                        onClick={() => {
                                            handleSearchLogMq();
                                        }}
                                    >
                                        {outletContext.intl.formatMessage({id: "global.search"})}
                                    </Button>
                                    <Button
                                        icon={<IconUndo />}
                                        loading={isLoad}
                                        onClick={() => {
                                            form.resetFields();

                                            setSelectedIdList([]);
                                            setSelectedList([]);
                                            setSortList([]);
                                        }}
                                    >
                                        {outletContext.intl.formatMessage({id: "global.reset"})}
                                    </Button>
                                </React.Fragment>
                            ) : null}
                            {main.permissionList.indexOf(Util.md5("/log/mq/backend/website/v1/save")) > -1 ||
                            main.permissionList.indexOf(Util.md5("/log/mq/backend/website/v1/delete")) > -1 ? (
                                <React.Fragment>
                                    <Divider type="vertical" />
                                    {main.permissionList.indexOf(Util.md5("/log/mq/backend/website/v1/save")) > -1 ? (
                                        <Button
                                            icon={<IconPlusCircle />}
                                            onClick={() => {
                                                setIsDetail(true);
                                                setAction("save");
                                                setId("");
                                            }}
                                        >
                                            {outletContext.intl.formatMessage({id: "global.save"})}
                                        </Button>
                                    ) : null}
                                    {main.permissionList.indexOf(Util.md5("/log/mq/backend/website/v1/delete")) > -1 ? (
                                        <Button
                                            icon={<IconDelete />}
                                            disabled={selectedIdList.length == 0}
                                            onClick={() => {
                                                handleDeleteLogMq();
                                            }}
                                        >
                                            {outletContext.intl.formatMessage({id: "global.delete"})}
                                        </Button>
                                    ) : null}
                                </React.Fragment>
                            ) : null}
                            {main.permissionList.indexOf(Util.md5("/log/mq/backend/website/v1/upload/process")) > -1 ||
                            main.permissionList.indexOf(Util.md5("/log/mq/backend/website/v1/export/search")) > -1 ? (
                                <React.Fragment>
                                    <Divider type="vertical" />
                                    {main.permissionList.indexOf(Util.md5("/log/mq/backend/website/v1/upload/process")) > -1 ? (
                                        <Button
                                            icon={<IconUpload />}
                                            onClick={() => {
                                                setIsImport(true);
                                            }}
                                        >
                                            {outletContext.intl.formatMessage({id: "global.import"})}
                                        </Button>
                                    ) : null}
                                    {main.permissionList.indexOf(Util.md5("/log/mq/backend/website/v1/export/search")) > -1 ? (
                                        <Dropdown
                                            droplist={
                                                <Menu
                                                    onClickMenuItem={(key) => {
                                                        if (key == "search") {
                                                            handleExportLogMqSearch();
                                                        } else if (key == "selected") {
                                                            handleExportLogMqSelected();
                                                        } else if (key == "history") {
                                                            setIsProcess(true);
                                                            setProcessType(1);
                                                        }
                                                    }}
                                                >
                                                    <Menu.Item key="search">{outletContext.intl.formatMessage({id: "global.export"})}</Menu.Item>
                                                    <Menu.Item key="selected" disabled={selectedIdList.length == 0}>
                                                        {outletContext.intl.formatMessage({id: "global.exportSelected"})} {selectedIdList.length}
                                                    </Menu.Item>
                                                    <Divider style={{margin: "4px 0"}} />
                                                    <Menu.Item key="history">
                                                        {outletContext.intl.formatMessage({id: "global.exportHistory"})}
                                                    </Menu.Item>
                                                </Menu>
                                            }
                                            trigger="click"
                                        >
                                            <Button icon={<IconDownload />}>{outletContext.intl.formatMessage({id: "global.export"})}</Button>
                                        </Dropdown>
                                    ) : null}
                                </React.Fragment>
                            ) : null}
                        </Space>
                        <Sort sortList={sortList} setSortList={setSortList} />
                        <Table
                            className="table"
                            columns={columnList.concat([operationColumn])}
                            rowKey="id"
                            data={logMqList}
                            rowSelection={{
                                type: selectType,
                                columnWidth: 50,
                                selectedRowKeys: selectedIdList,
                                onChange: (selectedRowKeys) => {
                                    setSelectedIdList(Util.getSelectedIdList(selectedIdList, logMqList, "id", selectedRowKeys));
                                    setSelectedList(Util.getSelectedList(selectedList, logMqList, "id", selectedRowKeys));
                                },
                            }}
                            scroll={{x: 50 + columnList.length * 150 + operationColumn.width}}
                            border={false}
                            pagination={false}
                            onChange={(pagination, sorter, filters) => {
                                setSortList(Util.getSortList(sortList, sorter, columnList));
                            }}
                        />
                        <div className="pagination">
                            <div className="pagination_selected">
                                {main.permissionList.indexOf(Util.md5("/log/mq/backend/website/v1/list")) > -1 ? (
                                    <React.Fragment>
                                        <Button
                                            onClick={() => {
                                                setIsSelected(true);
                                            }}
                                        >
                                            {outletContext.intl.formatMessage({id: "global.selected"})} {selectedIdList.length}
                                        </Button>
                                    </React.Fragment>
                                ) : null}
                            </div>
                            {typeof logMqCount == "undefined" ? null : (
                                <Pagination
                                    current={logMqPageIndex}
                                    total={logMqCount}
                                    simple={main.isMobile}
                                    showTotal
                                    sizeCanChange={!main.isMobile}
                                    onChange={(pageIndex, pageSize) => {
                                        handleLoadLogMqList(pageIndex, pageSize);
                                    }}
                                />
                            )}
                        </div>
                    </Card>
                </Form>
                <div className="card_bottom"></div>
            </Scrollbars>
            {isLoad ? <Loading /> : null}
            {isDetail ? (
                <LogMqDetail
                    visible={isDetail}
                    action={action}
                    id={id}
                    onClose={() => {
                        setIsDetail(false);
                    }}
                    onSubmit={() => {
                        setIsDetail(false);

                        handleLoadLogMqList(logMqPageIndex, logMqPageSize);
                    }}
                />
            ) : null}
            {isProcess ? (
                <Process
                    visible={isProcess}
                    apiUrl={process.env.API_URL}
                    processModule="logMq"
                    processType={processType}
                    onClose={() => {
                        setIsProcess(false);
                    }}
                />
            ) : null}
            {isImport ? (
                <Import
                    visible={isImport}
                    name={outletContext.intl.formatMessage({id: "logMq"})}
                    templateUrl={process.env.API_URL + "/log/mq/backend/website/v1/export/template"}
                    uploadUrl={process.env.API_URL + "/log/mq/backend/website/v1/upload/process"}
                    startUrl={process.env.API_URL + "/log/mq/backend/website/v1/import/process"}
                    accept=".xls,.xlsx"
                    onClose={() => {
                        setIsImport(false);
                    }}
                    onHistory={() => {
                        setIsProcess(true);
                        setProcessType(0);
                    }}
                />
            ) : null}
            {isSelected ? (
                <Selected
                    visible={isSelected}
                    url={process.env.API_URL + "/log/mq/backend/website/v1/list/selected"}
                    selectedIdListKey="idList"
                    selectedIdList={selectedIdList}
                    sortList={sortList}
                    rowKey="id"
                    columnList={columnList}
                    onClose={() => {
                        setIsSelected(false);
                    }}
                    onRemove={(idList) => {
                        setSelectedIdList(Util.removeSelectedIdList(selectedIdList, idList));
                        setSelectedList(Util.removeSelectedList(selectedList, "id", idList));
                    }}
                />
            ) : null}
        </React.Fragment>
    );
};

LogMqIndex.propTypes = {
    autoHeight: PropTypes.bool,
    breadcrumb: PropTypes.object,
    selectType: PropTypes.string,
    onSelect: PropTypes.func,
};

export default Util.withRouter(LogMqIndex);
