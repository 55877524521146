import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {Message, Breadcrumb, Card, Grid, Space, Button, Statistic, DatePicker} from "@arco-design/web-react";
import {IconUser, IconUserAdd, IconUserGroup, IconCustomerService, IconSearch, IconUpload, IconDownload, IconSync} from "@arco-design/web-react/icon";
import {VChart} from "@visactor/react-vchart";
import {Scrollbars} from "react-custom-scrollbars-2";
import {useOutletContext} from "react-router-dom";
import PropTypes from "prop-types";

import Util from "../../../../../nowui-common/js/util";

import Error from "../../../../../nowui-common/component/error";
import Loading from "../../../../../nowui-common/component/loading";

const DashboardIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [breadcrumbList, setBreadcrumbList] = useState([]);
    const [normalMember, setNormalMember] = useState(0);
    const [rechargeMember, setRechargeMember] = useState(0);
    const [consultantMember, setConsultantMember] = useState(0);
    const [serviceMember, setServiceMember] = useState(0);
    const [strtDate, setStrtDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        handleLoadDashboard();

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        setErrorTitle("");
        setErrorList([]);

        let breadcrumbList = Util.getBreadcrumb(outletContext, main.menuList, "/dashboard/index");
        setBreadcrumbList(breadcrumbList);
    }, [localStorage.getItem("locale")]);

    const handleLoadDashboard = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.request(outletContext, {
            url: process.env.API_URL + "/dashboard/backend/website/v1/item",
            controller: controller,
            data: {
                strtDate: strtDate,
                endDate: endDate,
            },
            success: (response) => {
                setNormalMember(response.data.normalMember);
                setRechargeMember(response.data.rechargeMember);
                setConsultantMember(response.data.consultantMember);
                setServiceMember(response.data.serviceMember);

                let data = [];
                for (let i = 0; i < response.data.rechargeList.length; i++) {
                    let recharge = response.data.rechargeList[i];

                    data.push({
                        name: recharge.rechargeAmount + "元",
                        value: recharge.rechargeCount,
                    });
                }
                setData(data);

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleLoadRecharge = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.request(outletContext, {
            url: process.env.API_URL + "/dashboard/backend/website/v1/recharge/list",
            controller: controller,
            data: {
                strtDate: strtDate,
                endDate: endDate,
            },
            success: (response) => {
                let data = [];
                for (let i = 0; i < response.data.length; i++) {
                    let recharge = response.data[i];

                    data.push({
                        name: recharge.rechargeAmount + "元",
                        value: recharge.rechargeCount,
                    });
                }
                setData(data);

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleRechargeDownload = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        Message.loading({
            id: "loading",
            content: outletContext.intl.formatMessage({id: "global.loading"}),
            duration: 0,
        });

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.download(outletContext, {
            url: process.env.API_URL + "/dashboard/backend/website/v1/recharge/download",
            controller: controller,
            data: {
                strtDate: strtDate,
                endDate: endDate,
            },
            success: (response) => {
                Message.success({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.exportSuccess"}),
                });

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error({
                    id: "loading",
                    content: response.message,
                });

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    return (
        <React.Fragment>
            <Scrollbars
                autoHeight={autoHeight}
                autoHeightMax={main.height - 300}
                renderView={({style, ...props}) => {
                    return <div className="scrollbar" style={style} {...props} />;
                }}
            >
                <Breadcrumb className="breadcrumb">
                    {breadcrumbList.map((breadcrumb, breadcrumbIndex) => {
                        return <Breadcrumb.Item key={breadcrumbIndex}>{breadcrumb.name}</Breadcrumb.Item>;
                    })}
                    {props.breadcrumb ? <Breadcrumb.Item key={props.breadcrumb.name}>{props.breadcrumb.name}</Breadcrumb.Item> : null}
                </Breadcrumb>
                <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                <div className="dashboard">
                    <Grid.Row gutter={[20, 20]}>
                        <Grid.Col xs={24} sm={12} md={6}>
                            <Card
                                bordered={false}
                                bodyStyle={{
                                    backgroundImage: "linear-gradient(to right bottom, rgb(236, 71, 134), rgb(185, 85, 164))",
                                }}
                            >
                                <div className="dashboard_item">
                                    <Statistic
                                        title={outletContext.intl.formatMessage({id: "dashboard.normalMember"})}
                                        value={normalMember}
                                        groupSeparator
                                    />
                                    <IconUserGroup style={{fontSize: "36px"}} />
                                </div>
                            </Card>
                        </Grid.Col>
                        <Grid.Col xs={24} sm={12} md={6}>
                            <Card
                                bordered={false}
                                bodyStyle={{
                                    backgroundImage: "linear-gradient(to right bottom, rgb(134, 94, 192), rgb(81, 68, 180))",
                                }}
                            >
                                <div className="dashboard_item">
                                    <Statistic
                                        title={outletContext.intl.formatMessage({id: "dashboard.rechargeMember"})}
                                        value={rechargeMember}
                                        groupSeparator
                                    />
                                    <IconUserAdd style={{fontSize: "36px"}} />
                                </div>
                            </Card>
                        </Grid.Col>
                        <Grid.Col xs={24} sm={12} md={6}>
                            <Card
                                bordered={false}
                                bodyStyle={{
                                    backgroundImage: "linear-gradient(to right bottom, rgb(86, 205, 243), rgb(113, 157, 227))",
                                }}
                            >
                                <div className="dashboard_item">
                                    <Statistic
                                        title={outletContext.intl.formatMessage({id: "dashboard.consultantMember"})}
                                        value={consultantMember}
                                        groupSeparator
                                    />
                                    <IconUser style={{fontSize: "36px"}} />
                                </div>
                            </Card>
                        </Grid.Col>
                        <Grid.Col xs={24} sm={12} md={6}>
                            <Card
                                bordered={false}
                                bodyStyle={{
                                    backgroundImage: "linear-gradient(to right bottom, rgb(252, 188, 37), rgb(246, 128, 87))",
                                }}
                            >
                                <div className="dashboard_item">
                                    <Statistic
                                        title={outletContext.intl.formatMessage({id: "dashboard.serviceMember"})}
                                        value={serviceMember}
                                        groupSeparator
                                    />
                                    <IconCustomerService style={{fontSize: "36px"}} />
                                </div>
                            </Card>
                        </Grid.Col>
                        <Grid.Col xs={24} sm={24}>
                            <Card
                                title={
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        {outletContext.intl.formatMessage({id: "dashboard.rechargeStatistics"})}
                                        <Space size="medium">
                                            <DatePicker.RangePicker
                                                onChange={(dateString, date) => {
                                                    if (dateString) {
                                                        setStrtDate(dateString[0]);
                                                        setEndDate(dateString[1]);
                                                    } else {
                                                        setStrtDate(null);
                                                        setEndDate(null);
                                                    }
                                                }}
                                            />
                                            <Button
                                                type="primary"
                                                icon={<IconSearch />}
                                                loading={isLoad}
                                                onClick={() => {
                                                    handleLoadRecharge();
                                                }}
                                            >
                                                {outletContext.intl.formatMessage({id: "global.search"})}
                                            </Button>
                                            <Button
                                                icon={<IconDownload />}
                                                loading={isLoad}
                                                onClick={() => {
                                                    handleRechargeDownload();
                                                }}
                                            >
                                                {outletContext.intl.formatMessage({id: "global.export"})}
                                            </Button>
                                        </Space>
                                    </div>
                                }
                                bordered={false}
                            >
                                <VChart
                                    spec={{
                                        type: "bar",
                                        data: [
                                            {
                                                id: "barData",
                                                values: data,
                                            },
                                        ],
                                        xField: "name",
                                        yField: "value",
                                        background: "transparent",
                                    }}
                                />
                            </Card>
                        </Grid.Col>
                    </Grid.Row>
                </div>
            </Scrollbars>
            {isLoad ? <Loading /> : null}
        </React.Fragment>
    );
};

DashboardIndex.propTypes = {
    autoHeight: PropTypes.bool,
    breadcrumb: PropTypes.object,
    selectType: PropTypes.string,
    onSelect: PropTypes.func,
};

export default Util.withRouter(DashboardIndex);
