import React, {useState, useRef, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useSelector} from "react-redux";
import {Message, Modal, Card, Space, Button, Form, Grid, Input} from "@arco-design/web-react";
import {IconCloseCircle, IconSave} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

import Util from "../../js/util";

import Error from "../error";
import Loading from "../loading";

const PasswordIndex = ({outletContext = {}, autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);

    useEffect(() => {
        if (props.visible) {
        }
    }, [props.visible]);

    const handleSubmitPassword = () => {
        if (request.current) {
            return;
        }

        form.validate()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                Util.request(outletContext, {
                    url: props.changePasswordUrl,
                    controller: controller,
                    data: {
                        userPassword: Util.md5(data.userPassword),
                    },
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        props.onSubmit();
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV == "development") {
                    console.log(error);
                }
            });
    };

    return (
        <React.Fragment>
            <Modal
                style={{top: "100px", width: main.width <= 520 ? "100%" : "520px"}}
                title={<div style={{textAlign: "left"}}>{outletContext.intl.formatMessage({id: "user.changePassword"})}</div>}
                visible={props.visible}
                focusLock={false}
                alignCenter={false}
                maskClosable={false}
                onCancel={() => {
                    props.onClose();
                }}
                footer={
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{flex: 1, marginRight: "8px"}}></div>
                        <Space>
                            <Button
                                icon={<IconCloseCircle />}
                                onClick={() => {
                                    props.onClose();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.close"})}
                            </Button>
                            <Space>
                                <Button
                                    icon={<IconSave />}
                                    type="primary"
                                    loading={isLoad}
                                    onClick={() => {
                                        handleSubmitPassword();
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.ok"})}
                                </Button>
                            </Space>
                        </Space>
                    </div>
                }
            >
                <Scrollbars
                    autoHeight
                    autoHeightMax={main.height - 300}
                    renderView={({style, ...props}) => {
                        return <div style={style} {...props} />;
                    }}
                >
                    <div className="card_top"></div>
                    <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                    <Form form={form} autoComplete="off" layout="vertical">
                        <Card className="card" bordered={false}>
                            <div className="card_content">
                                <Grid.Row gutter={20}>
                                    <Grid.Col xs={24} sm={24} md={24} lg={24}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "user.userPassword"})}
                                            field="userPassword"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                                {
                                                    type: "string",
                                                    minLength: 0,
                                                    maxLength: 50,
                                                },
                                            ]}
                                        >
                                            <Input.Password
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                allowClear
                                                onPressEnter={() => {
                                                    handleSubmitPassword();
                                                }}
                                            />
                                        </Form.Item>
                                    </Grid.Col>
                                </Grid.Row>
                            </div>
                        </Card>
                    </Form>
                    {isLoad ? <Loading /> : null}
                </Scrollbars>
            </Modal>
        </React.Fragment>
    );
};

PasswordIndex.propTypes = {
    visible: PropTypes.bool.isRequired,
    changePasswordUrl: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default PasswordIndex;
