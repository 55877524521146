import React, {useEffect} from "react";
import {
    IconAt,
    IconCloudDownload,
    IconCodeBlock,
    IconCodeSquare,
    IconCode,
    IconCustomerService,
    IconDownload,
    IconExport,
    IconEyeInvisible,
    IconEye,
    IconHeart,
    IconHistory,
    IconHome,
    IconImport,
    IconLaunch,
    IconList,
    IconMessageBanned,
    IconMessage,
    IconMoreVertical,
    IconMore,
    IconPoweroff,
    IconRefresh,
    IconReply,
    IconSave,
    IconScan,
    IconSearch,
    IconCheckCircle,
    IconSend,
    IconSettings,
    IconShareAlt,
    IconShareExternal,
    IconShareInternal,
    IconStar,
    IconSync,
    IconThumbDown,
    IconThumbUp,
    IconTranslate,
    IconUpload,
    IconVoice,
    IconApps,
    IconArchive,
    IconBook,
    IconBranch,
    IconBug,
    IconBulb,
    IconCalendarClock,
    IconCalendar,
    IconCamera,
    IconCloud,
    IconCommand,
    IconCommon,
    IconCompass,
    IconCopyright,
    IconDashboard,
    IconDesktop,
    IconDice,
    IconDragDotVertical,
    IconDragDot,
    IconDriveFile,
    IconEar,
    IconEmail,
    IconEmpty,
    IconExperiment,
    IconFileAudio,
    IconFileImage,
    IconFilePdf,
    IconFileVideo,
    IconFile,
    IconFire,
    IconFolderAdd,
    IconFolderDelete,
    IconFolder,
    IconGift,
    IconIdcard,
    IconImageClose,
    IconImage,
    IconInteraction,
    IconLanguage,
    IconLayout,
    IconLocation,
    IconLock,
    IconLoop,
    IconMan,
    IconMenu,
    IconMindMapping,
    IconMobile,
    IconMoon,
    IconMosaic,
    IconNav,
    IconNotificationClose,
    IconNotification,
    IconPalette,
    IconPen,
    IconPhone,
    IconPrinter,
    IconPublic,
    IconPushpin,
    IconQrcode,
    IconRobotAdd,
    IconRobot,
    IconSafe,
    IconSchedule,
    IconShake,
    IconSkin,
    IconStamp,
    IconStorage,
    IconSubscribeAdd,
    IconSubscribe,
    IconSubscribed,
    IconSun,
    IconTag,
    IconTags,
    IconThunderbolt,
    IconTool,
    IconTrophy,
    IconUnlock,
    IconUserAdd,
    IconUserGroup,
    IconUser,
    IconVideoCamera,
    IconWifi,
    IconWoman,
} from "@arco-design/web-react/icon";
import PropTypes from "prop-types";

let Icon = {};

const IconIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    useEffect(() => {}, []);

    if (props.name == "IconAt") {
        return <IconAt className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconCloudDownload") {
        return <IconCloudDownload className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconCodeBlock") {
        return <IconCodeBlock className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconCodeSquare") {
        return <IconCodeSquare className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconCode") {
        return <IconCode className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconCustomerService") {
        return <IconCustomerService className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconDownload") {
        return <IconDownload className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconExport") {
        return <IconExport className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconEyeInvisible") {
        return <IconEyeInvisible className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconEye") {
        return <IconEye className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconHeart") {
        return <IconHeart className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconHistory") {
        return <IconHistory className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconHome") {
        return <IconHome className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconImport") {
        return <IconImport className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconLaunch") {
        return <IconLaunch className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconList") {
        return <IconList className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconMessageBanned") {
        return <IconMessageBanned className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconMessage") {
        return <IconMessage className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconMoreVertical") {
        return <IconMoreVertical className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconMore") {
        return <IconMore className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconPoweroff") {
        return <IconPoweroff className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconRefresh") {
        return <IconRefresh className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconReply") {
        return <IconReply className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconSave") {
        return <IconSave className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconScan") {
        return <IconScan className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconSearch") {
        return <IconSearch className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconCheckCircle") {
        return <IconCheckCircle className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconSend") {
        return <IconSend className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconSettings") {
        return <IconSettings className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconShareAlt") {
        return <IconShareAlt className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconShareExternal") {
        return <IconShareExternal className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconShareInternal") {
        return <IconShareInternal className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconStar") {
        return <IconStar className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconSync") {
        return <IconSync className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconThumbDown") {
        return <IconThumbDown className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconThumbUp") {
        return <IconThumbUp className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconTranslate") {
        return <IconTranslate className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconUpload") {
        return <IconUpload className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconVoice") {
        return <IconVoice className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconApps") {
        return <IconApps className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconArchive") {
        return <IconArchive className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconBook") {
        return <IconBook className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconBranch") {
        return <IconBranch className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconBug") {
        return <IconBug className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconBulb") {
        return <IconBulb className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconCalendarClock") {
        return <IconCalendarClock className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconCalendar") {
        return <IconCalendar className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconCloud") {
        return <IconCloud className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconCommand") {
        return <IconCommand className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconCommon") {
        return <IconCommon className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconCompass") {
        return <IconCompass className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconCopyright") {
        return <IconCopyright className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconDashboard") {
        return <IconDashboard className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconDesktop") {
        return <IconDesktop className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconDice") {
        return <IconDice className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconDragDotVertical") {
        return <IconDragDotVertical className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconDragDot") {
        return <IconDragDot className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconDriveFile") {
        return <IconDriveFile className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconEar") {
        return <IconEar className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconEmail") {
        return <IconEmail className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconEmpty") {
        return <IconEmpty className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconExperiment") {
        return <IconExperiment className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconFileAudio") {
        return <IconFileAudio className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconFileImage") {
        return <IconFileImage className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconFilePdf") {
        return <IconFilePdf className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconFileVideo") {
        return <IconFileVideo className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconFile") {
        return <IconFile className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconFire") {
        return <IconFire className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconFolderAdd") {
        return <IconFolderAdd className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconFolderDelete") {
        return <IconFolderDelete className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconFolder") {
        return <IconFolder className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconGift") {
        return <IconGift className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconIdcard") {
        return <IconIdcard className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconImageClose") {
        return <IconImageClose className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconImage") {
        return <IconImage className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconInteraction") {
        return <IconInteraction className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconLanguage") {
        return <IconLanguage className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconLayout") {
        return <IconLayout className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconLocation") {
        return <IconLocation className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconLock") {
        return <IconLock className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconLoop") {
        return <IconLoop className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconMan") {
        return <IconMan className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconMenu") {
        return <IconMenu className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconMindMapping") {
        return <IconMindMapping className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconMobile") {
        return <IconMobile className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconMoon") {
        return <IconMoon className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconMosaic") {
        return <IconMosaic className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconNav") {
        return <IconNav className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconNotificationClose") {
        return <IconNotificationClose className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconNotification") {
        return <IconNotification className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconPalette") {
        return <IconPalette className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconPen") {
        return <IconPen className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconPhone") {
        return <IconPhone className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconPrinter") {
        return <IconPrinter className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconPublic") {
        return <IconPublic className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconPushpin") {
        return <IconPushpin className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconQrcode") {
        return <IconQrcode className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconRobotAdd") {
        return <IconRobotAdd className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconRobot") {
        return <IconRobot className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconSafe") {
        return <IconSafe className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconSchedule") {
        return <IconSchedule className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconShake") {
        return <IconShake className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconSkin") {
        return <IconSkin className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconStamp") {
        return <IconStamp className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconStorage") {
        return <IconStorage className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconSubscribeAdd") {
        return <IconSubscribeAdd className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconSubscribe") {
        return <IconSubscribe className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconSubscribed") {
        return <IconSubscribed className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconSun") {
        return <IconSun className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconTag") {
        return <IconTag className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconTags") {
        return <IconTags className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconThunderbolt") {
        return <IconThunderbolt className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconTool") {
        return <IconTool className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconTrophy") {
        return <IconTrophy className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconUnlock") {
        return <IconUnlock className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconUserAdd") {
        return <IconUserAdd className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconUserGroup") {
        return <IconUserGroup className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconUser") {
        return <IconUser className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconVideoCamera") {
        return <IconVideoCamera className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconWifi") {
        return <IconWifi className={props.className ? props.className : ""} />;
    }

    if (props.name == "IconWoman") {
        return <IconWoman className={props.className ? props.className : ""} />;
    }

    return <React.Fragment></React.Fragment>;
};

IconIndex.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default IconIndex;
