import React, {forwardRef} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import SparkMD5 from "spark-md5";
import axios from "axios";
import mitt from "mitt";

export default {
    emitter: mitt(),
    getId() {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4";
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = "-";

        var uuid = s.join("");
        return uuid;
    },
    withRouter(Child) {
        return forwardRef((props, ref) => {
            const location = useLocation();
            const navigate = useNavigate();
            const params = useParams();
            return <Child {...props} navigate={navigate} location={location} params={params} />;
        });
    },
    getBreadcrumb(outletContext, menuList, url) {
        let breadcrumbList = [
            {
                name: outletContext.intl.formatMessage({id: "global.home"}),
            },
        ];
        let isExist = false;
        for (let i = 0; i < menuList.length; i++) {
            let menu = menuList[i];
            if (menu.childrenList.length > 0) {
                for (let j = 0; j < menu.childrenList.length; j++) {
                    let children = menu.childrenList[j];
                    if (url == children.menuUrl) {
                        isExist = true;

                        breadcrumbList.push({
                            name: menu.languageId ? outletContext.intl.formatMessage({id: menu.languageId}) : menu.menuName,
                        });
                        breadcrumbList.push({
                            name: children.languageId ? outletContext.intl.formatMessage({id: children.languageId}) : children.menuName,
                        });

                        break;
                    }
                }
            } else {
                if (url == menu.menuUrl) {
                    isExist = true;

                    breadcrumbList.push({
                        name: menu.languageId ? outletContext.intl.formatMessage({id: menu.languageId}) : menu.menuName,
                    });
                }
            }
            if (isExist) {
                break;
            }
        }
        return breadcrumbList;
    },
    getExpandIdList(expandIdList, dataList, rowKey) {
        for (let i = 0; i < dataList.length; i++) {
            let data = dataList[i];
            if (expandIdList.indexOf(data[rowKey]) == -1) {
                expandIdList.push(data[rowKey]);
            }
            if (data.childrenList) {
                this.getExpandIdList(expandIdList, data.childrenList, rowKey);
            }
        }
        return [...expandIdList];
    },
    getSelectedList(selectedList, dataList, rowKey, selectedRowKeys) {
        let idList = [];
        let selectedRow = [];
        const loop = (dataList, rowKey, idList, selectedRow) => {
            dataList.some((item, index, arr) => {
                idList.push(item[rowKey]);
                if (selectedRowKeys.indexOf(item[rowKey]) > -1) {
                    selectedRow.push(item);
                }

                if (item.childrenList) {
                    return loop(item.childrenList, rowKey, idList, selectedRow);
                }
            });
        };
        loop(dataList, rowKey, idList, selectedRow);

        for (let i = 0; i < idList.length; i++) {
            let index = -1;
            for (let j = 0; j < selectedList.length; j++) {
                if (idList[i] == selectedList[j][rowKey]) {
                    index = j;

                    break;
                }
            }
            if (index > -1) {
                selectedList.splice(index, 1);
            }
        }

        return [...selectedList.concat(selectedRow)];
    },
    removeSelectedList(selectedList, idList, rowKey) {
        for (let i = 0; i < idList.length; i++) {
            let index = -1;
            for (let j = 0; j < selectedList.length; j++) {
                if (idList[i] == selectedList[j][rowKey]) {
                    index = j;

                    break;
                }
            }
            if (index > -1) {
                selectedList.splice(index, 1);
            }
        }
        return [...selectedList];
    },
    getSelectedIdList(selectedIdList, dataList, rowKey, selectedRowKeys) {
        let idList = [];
        const loop = (dataList, rowKey, idList) => {
            dataList.some((item, index, arr) => {
                idList.push(item[rowKey]);

                if (item.childrenList) {
                    return loop(item.childrenList, rowKey, idList);
                }
            });
        };
        loop(dataList, rowKey, idList);

        for (let i = 0; i < idList.length; i++) {
            let index = -1;
            for (let j = 0; j < selectedIdList.length; j++) {
                if (idList[i] == selectedIdList[j]) {
                    index = j;

                    break;
                }
            }
            if (index > -1) {
                selectedIdList.splice(index, 1);
            }
        }
        return [...selectedIdList.concat(selectedRowKeys)];
    },
    setSelectedList(selectedList, rowKey, idList) {
        for (let i = 0; i < idList.length; i++) {
            let index = -1;
            for (let j = 0; j < selectedList.length; j++) {
                if (idList[i] == selectedList[j][rowKey]) {
                    index = j;

                    break;
                }
            }
            if (index > -1) {
                selectedList.splice(index, 1);
            }
        }
        return [...selectedList];
    },
    removeSelectedIdList(selectedIdList, idList) {
        for (let i = 0; i < idList.length; i++) {
            let index = -1;
            for (let j = 0; j < selectedIdList.length; j++) {
                if (idList[i] == selectedIdList[j]) {
                    index = j;

                    break;
                }
            }
            if (index > -1) {
                selectedIdList.splice(index, 1);
            }
        }
        return [...selectedIdList];
    },
    changeSortList(sortList, setSortList, columnList) {
        for (let i = 0; i < sortList.length; i++) {
            for (let j = 0; j < columnList.length; j++) {
                if (sortList[i].field == columnList[j].dataIndex) {
                    sortList[i].title = columnList[j].title;
                    break;
                }
            }
        }
        setSortList([...sortList]);
    },
    getSortList(sortList, sorter, columnList) {
        let column = {};
        for (let i = 0; i < columnList.length; i++) {
            if (columnList[i].dataIndex == sorter.field) {
                column = columnList[i];
                break;
            }
        }
        let index = -1;
        for (let i = 0; i < sortList.length; i++) {
            if (sortList[i].field == sorter.field) {
                index = i;
                break;
            }
        }
        if (sorter.direction == "ascend" || sorter.direction == "descend") {
            if (index > -1) {
                sortList[index] = {
                    field: sorter.field,
                    title: column.title,
                    direction: sorter.direction,
                };
            } else {
                sortList.push({
                    field: sorter.field,
                    title: column.title,
                    direction: sorter.direction,
                });
            }
        } else {
            if (index > -1) {
                sortList[index] = {
                    field: sorter.field,
                    title: column.title,
                    direction: "ascend",
                };
            } else {
                sortList.push({
                    field: sorter.field,
                    title: column.title,
                    direction: "ascend",
                });
            }
        }
        return [...sortList];
    },
    navigateTo(props, config) {
        if (config.url.indexOf("http") > -1) {
            window.location.href = config.url;
        } else {
            if (props.location.pathname == config.url) {
                return;
            }

            props.navigate(config.url);
        }
    },
    redirectTo(props, config) {
        if (config.url.indexOf("http") > -1) {
            window.location.href = config.url;
        } else {
            if (props.location.pathname == config.url) {
                return;
            }

            props.navigate(config.url, {replace: true});
        }
    },
    goBack(props, config) {
        props.navigate(config && typeof config.index != "undefined" ? config.index : -1);
    },
    request(outletContext, config) {
        let data = Object.assign({}, config.data);
        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("authorization"),
            Locale: localStorage.getItem("locale"),
        };
        if (config.headers) {
            headers = Object.assign(headers, config.headers);
        }
        if (process.env.TENANT_ID) {
            headers.Tenant = process.env.TENANT_ID;
        }

        axios({
            method: "post",
            url: config.url,
            signal: config.controller.signal,
            headers: headers,
            data: data,
        })
            .then((response) => {
                if (response.data.code === 0) {
                    config.success(response.data);
                } else {
                    config.error(response.data);
                }

                config.complete(response);
            })
            .catch(function (error) {
                if (process.env.NODE_ENV == "development") {
                    console.log(config.url);
                    console.log(error);
                }

                config.error({
                    message: outletContext.intl.formatMessage({id: "global.errorMessage"}),
                    error: [],
                });

                config.complete({});
            });
    },
    download(outletContext, config) {
        let data = Object.assign({}, config.data);
        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("authorization"),
            Locale: localStorage.getItem("locale"),
        };
        if (config.headers) {
            headers = Object.assign(headers, config.headers);
        }
        if (process.env.TENANT_ID) {
            headers.Tenant = process.env.TENANT_ID;
        }

        axios({
            method: "post",
            url: config.url,
            signal: config.controller.signal,
            headers: headers,
            data: data,
            responseType: "blob",
        })
            .then((response) => {
                let fileReader = new FileReader();
                fileReader.onload = function () {
                    try {
                        let response = JSON.parse(this.result);
                        if (response.data.code === 0) {
                        } else {
                            config.error(response);
                        }
                    } catch (error) {
                        const blob = new Blob([response.data]);
                        const fileName = decodeURIComponent(response.headers.filename);
                        if ("download" in document.createElement("a")) {
                            const elink = document.createElement("a");
                            elink.download = fileName;
                            elink.style.display = "none";
                            elink.href = URL.createObjectURL(blob);
                            document.body.appendChild(elink);
                            elink.click();
                            URL.revokeObjectURL(elink.href);
                            document.body.removeChild(elink);
                        } else {
                            navigator.msSaveBlob(blob, fileName);
                        }

                        config.success();
                    }

                    config.complete();
                };

                fileReader.readAsText(response.data);
            })
            .catch(function (error) {
                if (process.env.NODE_ENV == "development") {
                    console.log(config.url);
                    console.log(error);
                }

                config.error({
                    message: outletContext.intl.formatMessage({id: "global.errorMessage"}),
                    error: [],
                });

                config.complete({});
            });
    },
    md5(data) {
        return SparkMD5.hash(data);
    },
};
