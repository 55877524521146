import React, {useState, useRef, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useSelector} from "react-redux";
import {Message, Modal, Card, Space, Button, Tree} from "@arco-design/web-react";
import {IconCloseCircle, IconSave} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

import Util from "../../js/util";

import Error from "../error";
import Loading from "../loading";

const TreeIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [expandIdList, setExpandIdList] = useState([]);

    useEffect(() => {
        if (props.visible) {
            setDataList([...props.dataList]);

            setExpandIdList(Util.getExpandIdList(expandIdList, props.dataList, props.rowKey));
        }
    }, [props.visible]);

    const handleUpdateSort = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        Message.loading({
            id: "loading",
            content: outletContext.intl.formatMessage({id: "global.loading"}),
            duration: 0,
        });

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        const loop = (dataList) => {
            let treeList = [];
            dataList.some((item, index, arr) => {
                let tree = {};
                tree[props.rowKey] = item[props.rowKey];
                tree.childrenList = loop(item.childrenList, dataList);
                treeList.push(tree);
            });
            return treeList;
        };

        let treeList = loop(dataList);

        let data = {};
        data[props.listName] = treeList;

        Util.request(outletContext, {
            url: props.sortUrl,
            controller: controller,
            data: data,
            success: (response) => {
                Message.success({
                    id: "loading",
                    content: response.message,
                });

                request.current = false;
                setIsLoad(false);

                props.onSubmit();
            },
            error: (response) => {
                Message.error({
                    id: "loading",
                    content: response.message,
                });

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    return (
        <React.Fragment>
            <Modal
                style={{top: "100px", width: main.width <= 520 ? "100%" : "520px"}}
                title={<div style={{textAlign: "left"}}>{outletContext.intl.formatMessage({id: "global.sort"})}</div>}
                visible={props.visible}
                focusLock={false}
                alignCenter={false}
                maskClosable={false}
                onCancel={() => {
                    props.onClose();
                }}
                footer={
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{flex: 1, marginRight: "8px"}}></div>
                        <Space>
                            <Button
                                icon={<IconCloseCircle />}
                                onClick={() => {
                                    props.onClose();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.close"})}
                            </Button>
                            <Button
                                icon={<IconSave />}
                                type="primary"
                                loading={isLoad}
                                onClick={() => {
                                    handleUpdateSort();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.ok"})}
                            </Button>
                        </Space>
                    </div>
                }
            >
                <Scrollbars
                    autoHeight
                    autoHeightMax={main.height - 300}
                    renderView={({style, ...props}) => {
                        return <div style={style} {...props} />;
                    }}
                >
                    <div className="card_top"></div>
                    <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                    <Card className="card" bordered={false}>
                        <div className="card_content">
                            {dataList.length > 0 ? (
                                <Tree
                                    showLine
                                    draggable
                                    blockNode
                                    size="large"
                                    onDrop={({dragNode, dropNode, dropPosition}) => {
                                        const loop = (data, key, callback) => {
                                            data.some((item, index, arr) => {
                                                if (item[props.rowKey] === key) {
                                                    callback(item, index, arr);
                                                    return true;
                                                }

                                                if (item.childrenList) {
                                                    return loop(item.childrenList, key, callback);
                                                }
                                            });
                                        };

                                        const data = [...dataList];
                                        let dragItem;
                                        loop(data, dragNode.props._key, (item, index, arr) => {
                                            arr.splice(index, 1);
                                            dragItem = item;
                                        });

                                        if (dropPosition === 0) {
                                            loop(data, dropNode.props._key, (item, index, arr) => {
                                                item.childrenList = item.childrenList || [];
                                                item.childrenList.push(dragItem);
                                            });
                                        } else {
                                            loop(data, dropNode.props._key, (item, index, arr) => {
                                                arr.splice(dropPosition < 0 ? index : index + 1, 0, dragItem);
                                            });
                                        }

                                        setDataList([...data]);
                                    }}
                                    fieldNames={{
                                        key: props.rowKey,
                                        title: props.rowTitle,
                                        children: "childrenList",
                                    }}
                                    treeData={dataList}
                                    expandedKeys={expandIdList}
                                    onExpand={(keys) => {
                                        setExpandIdList(keys);
                                    }}
                                />
                            ) : null}
                        </div>
                    </Card>
                    {isLoad ? <Loading /> : null}
                </Scrollbars>
            </Modal>
        </React.Fragment>
    );
};

TreeIndex.propTypes = {
    visible: PropTypes.bool.isRequired,
    sortUrl: PropTypes.string.isRequired,
    rowKey: PropTypes.string.isRequired,
    rowTitle: PropTypes.string.isRequired,
    listName: PropTypes.string.isRequired,
    dataList: PropTypes.array.isRequired,
    levelLimit: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default TreeIndex;
