import React, {useState, useRef, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useSelector} from "react-redux";
import {Message, Modal, Space, Button, Card, Upload} from "@arco-design/web-react";
import {IconCloseCircle, IconSave, IconHistory} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

import Util from "../../js/util";

import Error from "../error";
import Loading from "../loading";

const ImportIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        let isSubmit = false;
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i].status === "done") {
                if (fileList[i].response.code > 0) {
                } else {
                    isSubmit = true;
                }
            }
        }
        setIsSubmit(isSubmit);
    }, [fileList]);

    const handleExportTemplate = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        Message.loading({
            id: "loading",
            content: outletContext.intl.formatMessage({id: "global.loading"}),
            duration: 0,
        });

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.download(outletContext, {
            url: props.templateUrl,
            controller: controller,
            data: {},
            success: (response) => {
                Message.success({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.exportSuccess"}),
                });

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error({
                    id: "loading",
                    content: response.message,
                });

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleImportStart = (fileIdList) => {
        if (request.current) {
            return;
        }
        request.current = true;

        Message.loading({
            id: "loading",
            content: outletContext.intl.formatMessage({id: "global.loading"}),
            duration: 0,
        });

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.request(outletContext, {
            url: props.startUrl,
            controller: controller,
            data: {
                fileIdList: fileIdList,
            },
            success: (response) => {
                Message.success({
                    id: "loading",
                    content: response.message,
                });

                setFileList([]);

                request.current = false;
                setIsLoad(false);

                props.onHistory();
            },
            error: (response) => {
                Message.error({
                    id: "loading",
                    content: response.message,
                });

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    return (
        <React.Fragment>
            <Modal
                style={{top: "100px", width: main.width <= 520 ? "100%" : "520px"}}
                title={<div style={{textAlign: "left"}}>{outletContext.intl.formatMessage({id: "global.importData"})}</div>}
                visible={props.visible}
                focusLock={false}
                alignCenter={false}
                maskClosable={false}
                onCancel={() => {
                    props.onClose();
                }}
                footer={
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Button
                            icon={<IconHistory />}
                            onClick={() => {
                                props.onHistory();
                            }}
                        >
                            {outletContext.intl.formatMessage({id: "global.importHistory"})}
                        </Button>
                        <div style={{flex: 1}}></div>
                        <Space>
                            <Button
                                icon={<IconCloseCircle />}
                                onClick={() => {
                                    props.onClose();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.close"})}
                            </Button>
                            {isSubmit ? (
                                <Button
                                    icon={<IconSave />}
                                    type="primary"
                                    loading={isLoad}
                                    onClick={() => {
                                        let fileIdList = [];
                                        for (let i = 0; i < fileList.length; i++) {
                                            if (fileList[i].status === "done") {
                                                fileIdList.push(fileList[i].response.data.fileId);
                                            }
                                        }
                                        handleImportStart(fileIdList);
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.ok"})}
                                </Button>
                            ) : null}
                        </Space>
                    </div>
                }
            >
                <Scrollbars
                    autoHeight
                    autoHeightMax={main.height - 300}
                    renderView={({style, ...props}) => {
                        return <div style={style} {...props} />;
                    }}
                >
                    <div className="card_top"></div>
                    <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                    <Card className="card" bordered={false}>
                        <div className="warning">
                            <div className="warning_item">
                                <div className="warning_circle"></div>
                                <div className="warning_text">
                                    {outletContext.intl.formatMessage({id: "global.importDataWarning0"})}
                                    <span
                                        className="warning_text_active"
                                        onClick={() => {
                                            handleExportTemplate();
                                        }}
                                    >
                                        {props.name}
                                        {outletContext.intl.formatMessage({id: "global.importDataWarning1"})}
                                    </span>
                                    {outletContext.intl.formatMessage({id: "global.importDataWarning2"})}
                                </div>
                            </div>
                            <div className="warning_item">
                                <div className="warning_circle"></div>
                                <div className="warning_text">{outletContext.intl.formatMessage({id: "global.importDataWarning3"})}</div>
                            </div>
                            <div className="warning_item">
                                <div className="warning_circle"></div>
                                <div className="warning_text">{outletContext.intl.formatMessage({id: "global.importDataWarning4"})}</div>
                            </div>
                        </div>
                    </Card>
                    <div className="card_upload">
                        <Upload
                            drag
                            multiple={true}
                            name="file"
                            action={props.uploadUrl}
                            accept={props.accept}
                            headers={{
                                Authorization: localStorage.getItem("authorization"),
                                Locale: localStorage.getItem("locale"),
                            }}
                            fileList={fileList}
                            onChange={(fileList) => {
                                for (let i = 0; i < fileList.length; i++) {
                                    if (fileList[i].status === "done") {
                                        if (fileList[i].response.code > 0) {
                                            fileList[i].status = "error";
                                            fileList[i].response = fileList[i].response.message;
                                        }
                                    }
                                }
                                setFileList([...fileList]);
                            }}
                        />
                    </div>
                    {isLoad ? <Loading /> : null}
                </Scrollbars>
            </Modal>
        </React.Fragment>
    );
};

ImportIndex.propTypes = {
    visible: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    templateUrl: PropTypes.string.isRequired,
    uploadUrl: PropTypes.string.isRequired,
    accept: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onHistory: PropTypes.func.isRequired,
};

export default ImportIndex;
