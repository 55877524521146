import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {Message, Modal, Breadcrumb, Card, Grid, Form, Space, Button, Input, Table, Checkbox} from "@arco-design/web-react";
import {IconRefresh, IconClose, IconCloseCircle, IconSave, IconExpand, IconShrink} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import {useOutletContext} from "react-router-dom";
import PropTypes from "prop-types";

import Util from "../../../../../nowui-common/js/util";

import Error from "../../../../../nowui-common/component/error";
import Loading from "../../../../../nowui-common/component/loading";
import Icon from "../../../../../nowui-common/component/icon";

const RoleDetail = ({...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [breadcrumb, setBreadcrumb] = useState({});
    const [breadcrumbList, setBreadcrumbList] = useState([]);
    const [menuList, setMenuList] = useState([]);
    const [menuExpandIdList, setMenuExpandIdList] = useState([]);
    const [menuIdList, setMenuIdList] = useState([]);
    const [apiModuleList, setApiModuleList] = useState([]);
    const [permissionList, setPermissionList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        let breadcrumb = {};
        let breadcrumbList = Util.getBreadcrumb(outletContext, main.menuList, "/role/index");
        if (props.action == "save") {
            breadcrumb = {
                name: outletContext.intl.formatMessage({id: "global.new"}) + outletContext.intl.formatMessage({id: "role"}).toLowerCase(),
            };

            handleLoadRole();

            setIsRefresh(true);
        } else if (props.action == "update") {
            breadcrumb = {
                name: outletContext.intl.formatMessage({id: "global.modify"}) + outletContext.intl.formatMessage({id: "role"}).toLowerCase(),
            };

            handleLoadRole();

            setIsRefresh(true);
        }
        setBreadcrumb(breadcrumb);
        setBreadcrumbList(breadcrumbList.concat([breadcrumb]));

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        setErrorTitle("");
        setErrorList([]);

        return () => {};
    }, [localStorage.getItem("locale")]);

    const handleLoadRole = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.request(outletContext, {
            url: process.env.API_URL + "/role/backend/website/v1/item",
            controller: controller,
            data: {
                roleId: props.roleId,
            },
            success: (response) => {
                let apiExpandIdList = [];
                for (let i = 0; i < response.data.apiModuleList.length; i++) {
                    apiExpandIdList.push(response.data.apiModuleList[i].moduleId);
                }

                for (let i = 0; i < response.data.apiModuleList.length; i++) {
                    response.data.apiModuleList[i].indeterminate = false;
                    response.data.apiModuleList[i].checked = false;
                }

                let permissionList = [];
                for (let i = 0; i < response.data.permissionList.length; i++) {
                    let permission = response.data.permissionList[i];
                    for (let j = 0; j < response.data.apiModuleList.length; j++) {
                        for (let k = 0; k < response.data.apiModuleList[j].apiList.length; k++) {
                            if (permission == response.data.apiModuleList[j].apiList[k].apiUrl) {
                                permissionList.push(permission);

                                break;
                            }
                        }
                    }
                }

                setMenuList(response.data.menuList);
                setMenuIdList(response.data.menuIdList);
                setMenuExpandIdList(Util.getExpandIdList(menuExpandIdList, response.data.menuList, "menuId"));
                setApiModuleList(response.data.apiModuleList);
                setPermissionList(permissionList);

                form.setFieldsValue(response.data);

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    useEffect(() => {
        let total = 0;
        let count = 0;
        for (let i = 0; i < apiModuleList.length; i++) {
            let itemCount = 0;
            let apiModule = apiModuleList[i];
            for (let j = 0; j < apiModule.apiList.length; j++) {
                let api = apiModule.apiList[j];
                if (permissionList.indexOf(api.apiUrl) > -1) {
                    count++;
                    itemCount++;
                }
                total++;
            }
            apiModule.indeterminate = itemCount > 0 && itemCount < apiModule.apiList.length;
            apiModule.checked = itemCount == apiModule.apiList.length;
        }
        setApiModuleList([...apiModuleList]);
        setIndeterminate(count > 0 && count < total);
        setChecked(count == total);
    }, [permissionList]);

    const handleSubmitRole = () => {
        if (props.action == "save") {
            handleSaveRole();
        } else if (props.action == "update") {
            handleUpdateRole();
        }
    };

    const handleSaveRole = () => {
        if (request.current) {
            return;
        }

        form.validate()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                data.menuIdList = menuIdList;
                data.permissionList = permissionList;

                Util.request(outletContext, {
                    url: process.env.API_URL + "/role/backend/website/v1/save",
                    controller: controller,
                    data: data,
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        props.onSubmit();
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV == "development") {
                    console.log(error);
                }
            });
    };

    const handleUpdateRole = () => {
        if (request.current) {
            return;
        }

        form.validate()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                data.roleId = props.roleId;
                data.menuIdList = menuIdList;
                data.permissionList = permissionList;

                Util.request(outletContext, {
                    url: process.env.API_URL + "/role/backend/website/v1/update",
                    controller: controller,
                    data: data,
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        props.onSubmit();
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV == "development") {
                    console.log(error);
                }
            });
    };

    return (
        <React.Fragment>
            <Modal
                style={{top: "100px", width: main.width - 300 <= 1024 ? main.width + "px" : main.width - 300 + "px"}}
                title={
                    <React.Fragment>
                        <div className="arco-modal-title-text">{breadcrumb.name}</div>
                        <Space>
                            {main.permissionList.indexOf(Util.md5("/role/backend/website/v1/item")) > -1 && isRefresh ? (
                                <Button
                                    className="arco-modal-title-button"
                                    type="text"
                                    icon={<IconRefresh className="navigation_icon" />}
                                    onClick={() => {
                                        handleLoadRole();
                                    }}
                                ></Button>
                            ) : null}
                            <Button
                                className="arco-modal-title-button"
                                type="text"
                                icon={<IconClose className="navigation_icon" />}
                                onClick={() => {
                                    props.onClose();
                                }}
                            ></Button>
                        </Space>
                    </React.Fragment>
                }
                visible={props.visible}
                focusLock={false}
                alignCenter={false}
                maskClosable={false}
                closeIcon={false}
                footer={
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{flex: 1, marginRight: "8px"}}></div>
                        <Space>
                            <Button
                                icon={<IconCloseCircle />}
                                onClick={() => {
                                    props.onClose();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.close"})}
                            </Button>
                            {main.permissionList.indexOf(Util.md5("/role/backend/website/v1/save")) > -1 && props.action === "save" ? (
                                <Button
                                    type="primary"
                                    icon={<IconSave />}
                                    loading={isLoad}
                                    onClick={() => {
                                        handleSubmitRole();
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.save"})}
                                </Button>
                            ) : null}
                            {main.permissionList.indexOf(Util.md5("/role/backend/website/v1/update")) > -1 && props.action === "update" ? (
                                <Button
                                    type="primary"
                                    icon={<IconSave />}
                                    loading={isLoad}
                                    onClick={() => {
                                        handleSubmitRole();
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.update"})}
                                </Button>
                            ) : null}
                        </Space>
                    </div>
                }
            >
                <Scrollbars
                    autoHeight
                    autoHeightMax={main.height - 300}
                    renderView={({style, ...props}) => {
                        style.overflow = isLoad ? "hidden" : "auto";

                        return <div className="scrollbar" style={style} {...props} />;
                    }}
                >
                    <div style={{width: main.width - 300 <= 1024 ? main.width + "px" : main.width - 300 + "px"}}>
                        <Breadcrumb className="breadcrumb">
                            {breadcrumbList.map((breadcrumb, breadcrumbIndex) => {
                                return <Breadcrumb.Item key={breadcrumbIndex}>{breadcrumb.name}</Breadcrumb.Item>;
                            })}
                        </Breadcrumb>
                        <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                        <Form form={form} autoComplete="off" layout="vertical">
                            <Card className="card" title={outletContext.intl.formatMessage({id: "global.basicInformation"})} bordered={false}>
                                <Grid.Row gutter={20}>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "role.roleName"})}
                                            field="roleName"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                                {
                                                    type: "string",
                                                    minLength: 0,
                                                    maxLength: 50,
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                allowClear
                                                onPressEnter={() => {
                                                    handleSubmitRole();
                                                }}
                                            />
                                        </Form.Item>
                                    </Grid.Col>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "role.roleCode"})}
                                            field="roleCode"
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                                {
                                                    type: "string",
                                                    minLength: 0,
                                                    maxLength: 50,
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                allowClear
                                                onPressEnter={() => {
                                                    handleSubmitRole();
                                                }}
                                            />
                                        </Form.Item>
                                    </Grid.Col>
                                </Grid.Row>
                            </Card>
                            <Card className="card" title={outletContext.intl.formatMessage({id: "role.menuPermission"})} bordered={false}>
                                <div className="card_table_action">
                                    <Space className="action_detail" wrap>
                                        <Button
                                            icon={<IconExpand />}
                                            disabled={menuList.length == 0}
                                            onClick={() => {
                                                setMenuExpandIdList(Util.getExpandIdList(menuExpandIdList, menuList, "menuId"));
                                            }}
                                        >
                                            {outletContext.intl.formatMessage({id: "global.expand"})}
                                        </Button>
                                        <Button
                                            icon={<IconShrink />}
                                            disabled={menuList.length == 0}
                                            onClick={() => {
                                                setMenuExpandIdList([]);
                                            }}
                                        >
                                            {outletContext.intl.formatMessage({id: "global.shrink"})}
                                        </Button>
                                    </Space>
                                    <Table
                                        className="table"
                                        columns={[
                                            {
                                                dataIndex: "menuName",
                                                title: outletContext.intl.formatMessage({id: "menu.menuName"}),
                                                render: (col, record, index) => (
                                                    <React.Fragment>
                                                        <div className="menu_row">
                                                            {record.menuIcon ? <Icon name={record.menuIcon} /> : null}
                                                            {record.menuName}
                                                        </div>
                                                    </React.Fragment>
                                                ),
                                            },
                                            {
                                                dataIndex: "menuUrl",
                                                title: outletContext.intl.formatMessage({id: "menu.menuUrl"}),
                                            },
                                            {
                                                dataIndex: "menuRemark",
                                                title: outletContext.intl.formatMessage({id: "menu.menuRemark"}),
                                            },
                                            {
                                                dataIndex: "treeLevel",
                                                title: outletContext.intl.formatMessage({id: "global.treeLevel"}),
                                            },
                                        ]}
                                        rowKey="menuId"
                                        data={menuList}
                                        indentSize={19}
                                        childrenColumnName="childrenList"
                                        expandedRowKeys={menuExpandIdList}
                                        onExpand={(record, expanded) => {
                                            if (expanded) {
                                                menuExpandIdList.push(record.menuId);
                                            } else {
                                                menuExpandIdList.splice(menuExpandIdList.indexOf(record.menuId), 1);
                                            }
                                            setMenuExpandIdList([...menuExpandIdList]);
                                        }}
                                        rowSelection={{
                                            type: "checkbox",
                                            columnWidth: 50,
                                            selectedRowKeys: menuIdList,
                                            onChange: (selectedRowKeys) => {
                                                setMenuIdList(Util.getSelectedIdList(menuIdList, menuList, "menuId", selectedRowKeys));
                                            },
                                        }}
                                        scroll={{x: 5 * 150}}
                                        border={false}
                                        pagination={false}
                                    />
                                </div>
                            </Card>
                            <Card
                                className="card"
                                title={
                                    <Checkbox
                                        checked={checked}
                                        indeterminate={indeterminate}
                                        onChange={(checked) => {
                                            if (checked) {
                                                for (let i = 0; i < apiModuleList.length; i++) {
                                                    let apiModule = apiModuleList[i];
                                                    for (let j = 0; j < apiModule.apiList.length; j++) {
                                                        let api = apiModule.apiList[j];
                                                        if (permissionList.indexOf(api.apiUrl) == -1) {
                                                            permissionList.push(api.apiUrl);
                                                        }
                                                    }
                                                }
                                                setPermissionList([...permissionList]);
                                            } else {
                                                setPermissionList([]);
                                            }
                                        }}
                                    >
                                        {outletContext.intl.formatMessage({id: "role.apiPermission"})}
                                    </Checkbox>
                                }
                                bordered={false}
                            >
                                <div className="card_table">
                                    <Table
                                        className="table"
                                        columns={[
                                            {
                                                width: 150,
                                                dataIndex: "moduleName",
                                                title: outletContext.intl.formatMessage({id: "api.moduleName"}),
                                                render: (col, record, index) => (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            checked={record.checked}
                                                            indeterminate={record.checked ? false : record.indeterminate}
                                                            onChange={(checked) => {
                                                                if (checked) {
                                                                    for (let i = 0; i < record.apiList.length; i++) {
                                                                        let api = record.apiList[i];
                                                                        if (permissionList.indexOf(api.apiUrl) == -1) {
                                                                            permissionList.push(api.apiUrl);
                                                                        }
                                                                    }
                                                                    setPermissionList([...permissionList]);
                                                                } else {
                                                                    for (let i = 0; i < record.apiList.length; i++) {
                                                                        let api = record.apiList[i];
                                                                        if (permissionList.indexOf(api.apiUrl) > -1) {
                                                                            permissionList.splice(permissionList.indexOf(api.apiUrl), 1);
                                                                        }
                                                                    }
                                                                    setPermissionList([...permissionList]);
                                                                }
                                                            }}
                                                        >
                                                            {record.moduleName}
                                                        </Checkbox>
                                                    </React.Fragment>
                                                ),
                                            },
                                            {
                                                dataIndex: "permission",
                                                title: outletContext.intl.formatMessage({id: "api.apiUrl"}),
                                                render: (col, record, index) => (
                                                    <Space direction="vertical" style={{marginLeft: "38px"}}>
                                                        {record.apiList.map((api, apiIndex) => {
                                                            return (
                                                                <Checkbox
                                                                    key={apiIndex}
                                                                    checked={permissionList.indexOf(api.apiUrl) > -1}
                                                                    onChange={(event) => {
                                                                        if (event) {
                                                                            permissionList.push(api.apiUrl);
                                                                        } else {
                                                                            permissionList.splice(permissionList.indexOf(api.apiUrl), 1);
                                                                        }
                                                                        setPermissionList([...permissionList]);
                                                                    }}
                                                                >
                                                                    {api.apiName} {api.apiUrl}
                                                                </Checkbox>
                                                            );
                                                        })}
                                                    </Space>
                                                ),
                                            },
                                        ]}
                                        rowKey="moduleId"
                                        data={apiModuleList}
                                        scroll={{x: 700}}
                                        border={false}
                                        pagination={false}
                                    />
                                </div>
                            </Card>
                        </Form>
                        <div className="card_bottom"></div>
                    </div>
                </Scrollbars>
                {isLoad ? <Loading /> : null}
            </Modal>
        </React.Fragment>
    );
};

RoleDetail.propTypes = {
    visible: PropTypes.bool.isRequired,
    action: PropTypes.string.isRequired,
    roleId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default Util.withRouter(RoleDetail);
